import React, { FC } from "react";

import { ReactComponent as pageHistoryIcon } from "@App/assets/page-history.svg";
import { createDateString } from "@App/libs/dayjs";
import { ReactComponent as editIcon } from "@epam/assets/icons/common/content-edit-18.svg";
import { ReactComponent as cloudIcon } from "@epam/assets/icons/common/file-cloud_upload-24.svg";
import { ReactComponent as closeIcon } from "@epam/assets/icons/common/navigation-close-18.svg";
import { ReactComponent as checkIcon } from "@epam/assets/icons/common/notification-done-18.svg";
import { ReactComponent as lockIcon } from "@epam/assets/icons/common/action-lock-fill-18.svg";
import { ReactComponent as addIcon } from "@epam/assets/icons/common/action-add-18.svg";
import {
  Avatar,
  Button,
  FlexSpacer,
  LinkButton,
  Text,
  Dropdown,
} from "@epam/loveship";
import { ButtonProps } from "@epam/uui-components";
import { withNaming } from "Helpers/bemClassName";
import {
  useSitePagesActions,
  useSitePagesMethods,
  useSitePageVersions,
} from "@App/hooks/useSitePages";
import { IUser, IUserWithPerms } from "Store/actions/sitePage.actions.types";
import { EditedBy } from "./page-ribbon-edited-by";
import { Tooltip } from "Components/tooltip";

import "./page-ribbon.scss";
import { sitePageUrl } from "Helpers/constants";

interface IPageRibbonProps {
  sitePageId: number;
  publishedDate: Date;
  checkoutUser: IUser;
  currentUser: IUserWithPerms;
}

export const PageRibbon: FC<IPageRibbonProps> = ({
  publishedDate,
  sitePageId,
  currentUser,
  checkoutUser,
}) => {
  const cn = withNaming("page-ribbon");

  const {
    isSitePageInValid,
    isSitePageInVersionHistoryMode,
    isSitePageInEditMode,
    setSitePageEditMode,
    getSitePageVersions,
    setSitePageVersion,
  } = useSitePagesActions();

  const {
    onRepublishSitePage,
    onSaveAsDraftSitePage,
    onCheckOutSitePage,
    onDiscardCheckOutSitePage,
  } = useSitePagesMethods();

  const { currentVersionIndex, pageVersions, selectedPageVersion } =
    useSitePageVersions(sitePageUrl);
  const isEditMode = currentUser?.id === checkoutUser?.id;
  const isEditByOtherMode = !!checkoutUser && !isEditMode;
  const isVersionHistoryMode =
    !isEditMode && isSitePageInVersionHistoryMode && !!pageVersions;

  const handleRepublish = () => {
    onRepublishSitePage(sitePageId, sitePageUrl);
  };

  const handlePublishVersion = () => {
    setSitePageVersion(sitePageUrl, selectedPageVersion);
  };

  const handleSaveAsDraft = () => {
    onSaveAsDraftSitePage(sitePageId, sitePageUrl);
  };

  const handleEdit = () => {
    if (isEditMode) {
      setSitePageEditMode(true, sitePageUrl);
    } else {
      onCheckOutSitePage({
        sitePageId,
        sitePageUrl,
        currentUser,
        cb: () => setSitePageEditMode(true, sitePageUrl),
      });
    }
  };

  const handleGetPageHistory = () => {
    getSitePageVersions(sitePageUrl);
  };

  const handleDiscard = () => {
    onDiscardCheckOutSitePage({
      sitePageId,
      sitePageUrl,
    });
  };

  const renderLinkButton = (props: ButtonProps) => (
    <LinkButton cx={cn("button")} size="36" color="white" {...props} />
  );

  const republishButton = (
    <Button
      cx={cn("button", { republish: true })}
      size="30"
      icon={cloudIcon}
      caption="Republish"
      onClick={handleRepublish}
      isDisabled={isSitePageInValid}
    />
  );

  const publishThisVersionButton = (
    <Button
      cx={cn("button", { republish: true })}
      size="30"
      icon={cloudIcon}
      caption="Publish This Version"
      onClick={handlePublishVersion}
    />
  );

  let content = (
    <>
      {isEditMode &&
        renderLinkButton({
          caption: "Discard Changes",
          icon: closeIcon,
          onClick: handleDiscard,
        })}
      <FlexSpacer />
      <Text cx={cn("text")} color="night500" fontSize="14" lineHeight="18">
        {isEditMode ? "Draft saved" : "Published"}{" "}
        {createDateString(publishedDate, "DD-MMM-YYYY")}
      </Text>
      {renderLinkButton({
        caption: "Edit",
        icon: editIcon,
        onClick: handleEdit,
      })}
      <div className={cn("divider")} />
      {renderLinkButton({
        caption: "Page History",
        icon: pageHistoryIcon,
        onClick: handleGetPageHistory,
      })}
      {isEditMode && republishButton}
    </>
  );

  if (isEditMode && isSitePageInEditMode) {
    content = (
      <>
        {renderLinkButton({
          caption: "Save as Draft",
          icon: checkIcon,
          onClick: handleSaveAsDraft,
          isDisabled: isSitePageInValid,
        })}
        {renderLinkButton({
          caption: "Discard Changes",
          icon: closeIcon,
          onClick: handleDiscard,
        })}
        <FlexSpacer />
        {republishButton}
      </>
    );
  }

  if (isEditByOtherMode) {
    content = (
      <>
        <FlexSpacer />
        <Text color="night500" fontSize="14" lineHeight="18">
          Published {createDateString(publishedDate, "DD-MMM-YYYY")}
        </Text>
        <div className={cn("divider")} />
        <Avatar
          alt="avatar"
          size="24"
          img={`${process.env.REACT_APP_BASE_URL}/_layouts/15/userphoto.aspx?size=S&username=${checkoutUser?.email}`}
        />
        <Text
          cx={cn("text", { "edit-by-other": true })}
          color="night500"
          fontSize="14"
          lineHeight="18"
        >
          {checkoutUser?.name} is editing this page
        </Text>
        <Tooltip
          cx={cn("edit-tooltip")}
          trigger="click"
          content={(props) => (
            <EditedBy
              username={checkoutUser?.name}
              onDiscard={handleDiscard}
              {...props}
            />
          )}
          placement="bottom-end"
        >
          <Dropdown
            renderBody={() => null}
            renderTarget={(props) =>
              renderLinkButton({
                caption: "Edit",
                icon: lockIcon,
                cx: cn("button", { "edit-by-other": true }),
                ...props,
              })
            }
          />
        </Tooltip>
        <div className={cn("divider")} />
        {renderLinkButton({
          caption: "Page History",
          icon: pageHistoryIcon,
          onClick: handleGetPageHistory,
        })}
      </>
    );
  }

  if (isVersionHistoryMode) {
    content = (
      <>
        <Text color="night500" fontSize="14" lineHeight="18">
          {"Version"}
        </Text>
        <div className={cn("spacer")} />
        <Text color="night500" fontSize="14" lineHeight="18">
          {createDateString(
            selectedPageVersion?.Modified,
            "DD-MMM-YYYY, HH:mm A"
          )}
        </Text>
        <div className={cn("spacer")} />
        <Text color="night500" fontSize="14" lineHeight="18">
          {"by"}
        </Text>
        <div className={cn("spacer")} />
        <Avatar
          alt="avatar"
          img={`${process.env.REACT_APP_BASE_URL}/_layouts/15/userphoto.aspx?size=S&username=${selectedPageVersion?.Editor?.email}`}
          size="24"
        />
        <div className={cn("spacer")} />
        <Text color="night500" fontSize="14" lineHeight="18">
          {selectedPageVersion?.Editor?.name}
        </Text>
        <div className={cn("spacer")} />
        {currentVersionIndex === 0 && (
          <>
            <Text color="white" fontSize="14" lineHeight="18">
              —
            </Text>
            <div className={cn("spacer")} />
            <Text color="white" fontSize="14" lineHeight="18">
              Current Version
            </Text>
          </>
        )}
        <FlexSpacer />
        {publishThisVersionButton}
      </>
    );
  }

  return (
    <div className={cn("", ["flex justify-center"])}>
      <div className={cn("content")}>{content}</div>
    </div>
  );
};
