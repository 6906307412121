import React, { FC } from "react";

import { WebPartsParent } from "Components/web-parts/web-parts-parent";
import { IPathKeys } from "Helpers/sitePageUpdater";
import {
  IParentRichTextEditorWebPart,
  IRichTextEditorWebPart,
  IWebPart,
  WebPartTypes,
} from "SP/sitePages/sitePages.types";

import { WebPartsCard } from "./web-parts-card";

export interface ISelectDetailProps {
  sectionIndex: number;
  webPartIndex: number;
  column: string;
}

interface IWebPartProps {
  webPart: IWebPart;
  pathKeys: IPathKeys;
  selectDetailProps: ISelectDetailProps;
}

export const WebPart: FC<IWebPartProps> = ({
  webPart,
  pathKeys,
  selectDetailProps,
}) => {
  if (webPart.type === WebPartTypes.RichTextEditorWebPart) {
    const data = webPart.data as IRichTextEditorWebPart;

    return (
      <WebPartsCard
        data={data}
        webPart={webPart}
        pathKeys={pathKeys}
        surroundedBy={data.surroundedBy}
        selectDetailProps={selectDetailProps}
      />
    );
  } else if (webPart.type === WebPartTypes.ParentRichTextEditorWebPart) {
    const data = webPart.data as IParentRichTextEditorWebPart;

    return (
      <WebPartsParent
        data={data}
        webPart={webPart}
        pathKeys={pathKeys}
        selectDetailProps={selectDetailProps}
      />
    );
  }

  return null;
};
