import React, { FC, useEffect } from "react";
import _isEqual from "lodash/isEqual";
import { Prompt, RouteComponentProps } from "react-router-dom";
import { FlexRow } from "@epam/loveship";

import { Layout } from "Components/layout";
import { Sections } from "Components/Sections";
import {
  useSitePageVersions,
  useSitePagesActions,
  useSitePagesData,
  useSitePagesMethods,
} from "@App/hooks/useSitePages";
import { useUsersData } from "@App/hooks/useUsers";
import { useBeforeunload } from "@App/hooks/useBeforeunload";
import { PageRibbon } from "Components/page-ribbon";
import { PageDetails } from "Components/page-details";
import {
  setSitePageVersionHistoryMode,
  setSitePageEditMode,
  clearSitePageInValid,
} from "Store/actions/sitePages.actions";
import { PageVersions } from "Components/page-versions";

import css from "./DynamicSitePage.module.scss";
import { sitePageUrl } from "Helpers/constants";
import { ErrorPageComponent } from "./ErrorPage";

export const DynamicSitePage: FC<RouteComponentProps> = () => {
  const { loading, sitePage, editPageBackup, criticalError } =
    useSitePagesData(sitePageUrl);

  const { selectedPageVersion } = useSitePageVersions(sitePageUrl);

  const { isSitePageInVersionHistoryMode, isSitePageInEditMode } =
    useSitePagesActions();

  const { getSitePageByUrl } = useSitePagesMethods();
  const { currentUser } = useUsersData();

  const pageInfo = isSitePageInVersionHistoryMode
    ? selectedPageVersion?.pageInfo
    : sitePage?.pageInfo;

  const openPageDetails = currentUser?.canEditSitePage && isSitePageInEditMode;
  const openPageVersions =
    currentUser?.canEditSitePage &&
    !isSitePageInEditMode &&
    isSitePageInVersionHistoryMode;

  function isShowPrompt(): boolean {
    return isSitePageInEditMode && !_isEqual(editPageBackup, sitePage);
  }

  useEffect(() => {
    getSitePageByUrl(sitePageUrl);

    return () => {
      setSitePageVersionHistoryMode(sitePageUrl, false);
      setSitePageEditMode(false, sitePageUrl);
      clearSitePageInValid();
    };
  }, [sitePageUrl]);

  useBeforeunload((event: any) => {
    if (isShowPrompt()) {
      event.preventDefault();
    }
  });

  if (criticalError) {
    return <ErrorPageComponent error={criticalError} />;
  }

  return (
    <>
      <Prompt
        when={isShowPrompt()}
        message="Changes you made may not be saved."
      />
      {sitePage && currentUser?.canEditSitePage && (
        <PageRibbon
          sitePageId={sitePage?.Id}
          publishedDate={sitePage?.Modified}
          checkoutUser={sitePage?.CheckoutUser}
          currentUser={currentUser}
        />
      )}
      <Layout loading={loading} isContentLoaded={!!sitePage}>
        {sitePage && (
          <>
            <FlexRow
              cx={openPageDetails ? css.withSidePanel : ""}
              alignItems="top"
              spacing="12"
            >
              <Sections
                isEditMode={isSitePageInEditMode}
                sections={pageInfo?.sections ?? []}
              />
            </FlexRow>
            {openPageDetails && <PageDetails sitePage={sitePage} />}
            {openPageVersions && <PageVersions sitePageUrl={sitePageUrl} />}
          </>
        )}
      </Layout>
    </>
  );
};
