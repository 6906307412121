import { IList } from "@pnp/sp/lists";
import { spWeb } from "SP/configure";
import {
  IFolderInfo,
  INewSitePageInfo,
  ISitePage,
  ISitePageDTO,
  ISitePageVersionDTO,
} from "./sitePages.types";
import { CheckinType, fileFromServerRelativePath } from "@pnp/sp/files";
import {
  sitePageVersionsListRequestInfo,
  sitePagesListRequestInfo,
} from "./sitePages.constants";
import { IItemUpdateResult } from "@pnp/sp/items";
import { PermissionKind } from "@pnp/sp/security";

export class SitePagesRepository {
  private sitePagesList: IList = spWeb.lists.getByTitle("Site Pages");

  public async getById(id: number): Promise<ISitePageDTO> {
    return await this.sitePagesList.items
      .getById(id)
      .select(...sitePagesListRequestInfo.selectArray)
      .expand(...sitePagesListRequestInfo.expandArray)();
  }

  public async getSitePageByServerRelativeUrl(
    url: string
  ): Promise<ISitePageDTO> {
    const file = await fileFromServerRelativePath(spWeb, url).getItem();

    return await file
      .select(...sitePagesListRequestInfo.selectArray)
      .expand(...sitePagesListRequestInfo.expandArray)();
  }

  public async getSitePageVersions(
    pageId: number
  ): Promise<ISitePageVersionDTO[]> {
    return await this.sitePagesList.items
      .getById(pageId)
      .versions.select(...sitePageVersionsListRequestInfo.selectArray)();
  }

  public async checkIfUSerHasEditPermission(): Promise<boolean> {
    const perm = await this.sitePagesList.getCurrentUserEffectivePermissions();
    return this.sitePagesList.hasPermissions(
      perm,
      PermissionKind.EditListItems
    );
  }

  public async checkOutSitePage(id: number): Promise<void> {
    return await this.sitePagesList.items.getById(id).file.checkout();
  }

  public async checkInSitePage(id: number, comment?: string): Promise<void> {
    return await this.sitePagesList.items
      .getById(id)
      .file.checkin(comment, CheckinType.Major);
  }

  public async discardCheckOutSitePage(id: number): Promise<void> {
    await this.sitePagesList.items.getById(id).file.undoCheckout();
  }

  public async checkIfPageExist(
    folderServerRelativeUrl: string,
    pageTitle: string
  ): Promise<boolean> {
    let pageExist = false;
    pageTitle = this.clearPageName(pageTitle);
    try {
      await fileFromServerRelativePath(
        spWeb,
        `${folderServerRelativeUrl}/${pageTitle}.aspx`
      )();

      pageExist = true;
    } catch (error) {
      pageExist = false;
    }
    return pageExist;
  }

  private clearPageName = (pageTitle: string) => {
    return pageTitle
      .replaceAll(":", "")
      .replaceAll("/", "")
      .replaceAll("&", "");
  };

  public async addNewPage(pageInfo: INewSitePageInfo): Promise<number> {
    const pageTitle = this.clearPageName(pageInfo.pageTitle);

    const newPage = await spWeb.addClientsidePage(
      pageInfo.pageTitle,
      pageTitle,
      "Home"
    );
    const pageItem = await newPage.getItem<ISitePage>();
    const pageFile = await this.sitePagesList.items
      .getById(pageItem["Id"])
      .file();
    const newUrl = `${pageInfo.folderServerRelativeUrl}/${pageTitle}.aspx`;

    await fileFromServerRelativePath(
      spWeb,
      pageFile.ServerRelativeUrl
    ).moveByPath(newUrl, true, false);

    return pageItem["Id"];
  }

  public async updatePage(
    id: number,
    data: Partial<ISitePageDTO>
  ): Promise<IItemUpdateResult> {
    const updateResult: IItemUpdateResult = await this.sitePagesList.items
      .getById(id)
      .update(data);

    return updateResult;
  }
}
