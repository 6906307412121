import { ReactComponent as card } from "@App/assets/card.svg";
import { ReactComponent as navCard } from "@App/assets/nav-card.svg";
import { ReactComponent as noBg } from "@App/assets/no-bg.svg";

import { SitePageUpdaterKeys } from "Helpers/sitePageUpdater";
import { emptyTable } from "SP/sitePages/sitePages.constants";
import { WebPartTypes } from "SP/sitePages/sitePages.types";

export const webPartComponents = [
  {
    groupTitle: "Wrappers & Views",
    components: [
      {
        name: "Navigation Card",
        icon: navCard,
        iconName: "card",
        webPart: {
          updaterKey: SitePageUpdaterKeys.ADD_WEB_PART,
          type: WebPartTypes.ParentRichTextEditorWebPart,
          data: {
            webPartTitle: "Untitled",
            webPartTitleLink: "https://link.com",
            webPartLinkColor: "#EFF4FF",
            markup: emptyTable,
          },
        },
      },
      {
        name: "Content Card",
        icon: card,
        iconName: "card",
        webPart: {
          updaterKey: SitePageUpdaterKeys.ADD_WEB_PART,
          type: WebPartTypes.RichTextEditorWebPart,
          data: {
            surroundedBy: "accordion",
            accordionTitle: "Untitled",
            markup: emptyTable,
          },
        },
      },
      {
        name: "No-BG Card",
        icon: noBg,
        iconName: "clear-card",
        webPart: {
          updaterKey: SitePageUpdaterKeys.ADD_WEB_PART,
          type: WebPartTypes.RichTextEditorWebPart,
          data: {
            markup: emptyTable,
          },
        },
      },
    ],
  },
];
