import React, { FC } from "react";

import { FlexCell } from "@epam/loveship";

import { WebParts } from "Components/web-parts";
import { withNaming } from "Helpers/bemClassName";
import "./sections-layouts.scss";

import {
  IOneColumnLayoutProps,
  IOneThirdLayoutProps,
  IThreeColumnsLayoutProps,
  ITwoColumnsLayoutProps,
  IRowsLayoutProps,
} from "./sections-layouts.interface";
import { AddWebPartButton } from "Components/web-parts/web-parts-add-web-part";
import { sitePageUrl } from "Helpers/constants";
import { useSitePagesActions } from "@App/hooks/useSitePages";
import { SectionDescription } from "../section-description";
import { SectionTypes } from "SP/sitePages/sitePages.types";

const cn = withNaming("layout");

export const RowsLayout: FC<IRowsLayoutProps> = ({
  data,
  sectionIndex,
  description,
}) => {
  const { isSitePageInEditMode } = useSitePagesActions();
  return (
    <FlexCell
      cx={cn("", { "one-column": true })}
      grow={2}
      rawProps={{ "data-testid": SectionTypes.RowsLayout }}
    >
      <div
        className={cn("adaptive-row", [isSitePageInEditMode && "edit-mode"])}
      >
        {description && <SectionDescription text={description} />}
        <WebParts
          webParts={data.webParts}
          column="webParts"
          sectionIndex={sectionIndex}
          hideAddBtns
        />
      </div>
      {isSitePageInEditMode && (
        <AddWebPartButton
          sitePageUrl={sitePageUrl}
          sectionIndex={sectionIndex}
          column="webParts"
          position="next"
          webPartIndex={data.webParts.length - 1}
        />
      )}
    </FlexCell>
  );
};

export const OneColumnLayout: FC<IOneColumnLayoutProps> = ({
  data,
  sectionIndex,
}) => (
  <FlexCell
    cx={cn("", { "one-column": true })}
    grow={2}
    rawProps={{ "data-testid": SectionTypes.OneColumnLayout }}
  >
    <WebParts
      webParts={data.webParts}
      column="webParts"
      sectionIndex={sectionIndex}
    />
  </FlexCell>
);

export const TwoColumnsLayout: FC<ITwoColumnsLayoutProps> = ({
  data: { left, right },
  sectionIndex,
}) => (
  <>
    <FlexCell
      cx={cn("", { "two-column": true, left: true })}
      grow={1}
      alignSelf="flex-start"
      rawProps={{ "data-testid": SectionTypes.TwoColumnsLayout }}
    >
      <WebParts webParts={left} column="left" sectionIndex={sectionIndex} />
    </FlexCell>
    <FlexCell
      cx={cn("", { "two-column": true, right: true })}
      grow={1}
      alignSelf="flex-start"
    >
      <WebParts webParts={right} column="right" sectionIndex={sectionIndex} />
    </FlexCell>
  </>
);

export const ThreeColumnsLayout: FC<IThreeColumnsLayoutProps> = ({
  data: { left, center, right },
  sectionIndex,
}) => (
  <>
    <FlexCell
      cx={cn("", { "three-column": true, left: true })}
      grow={1}
      alignSelf="flex-start"
      rawProps={{ "data-testid": SectionTypes.ThreeColumnsLayout }}
    >
      <WebParts webParts={left} column="left" sectionIndex={sectionIndex} />
    </FlexCell>
    <FlexCell
      cx={cn("", { "three-column": true, center: true })}
      grow={1}
      alignSelf="flex-start"
    >
      <WebParts webParts={center} column="center" sectionIndex={sectionIndex} />
    </FlexCell>
    <FlexCell
      cx={cn("", { "three-column": true, right: true })}
      grow={1}
      alignSelf="flex-start"
    >
      <WebParts webParts={right} column="right" sectionIndex={sectionIndex} />
    </FlexCell>
  </>
);

export const OneThirdLayout: FC<IOneThirdLayoutProps> = ({
  isLeft,
  isRight,
  data: { left, right },
  sectionIndex,
}) => (
  <>
    <FlexCell
      cx={cn("", { "one-third": true, left: true })}
      grow={isLeft ? 1 : 2}
      alignSelf="flex-start"
      rawProps={{
        "data-testid": isLeft
          ? SectionTypes.OneThirdLeftLayout
          : SectionTypes.OneThirdRightLayout,
      }}
    >
      <WebParts webParts={left} column="left" sectionIndex={sectionIndex} />
    </FlexCell>
    <FlexCell
      cx={cn("", { "one-third": true, right: true })}
      grow={isRight ? 1 : 2}
      alignSelf="flex-start"
    >
      <WebParts webParts={right} column="right" sectionIndex={sectionIndex} />
    </FlexCell>
  </>
);
