import React, { FC } from "react";

import { isDetailCard } from "Helpers/utils";
import { useSitePagesActions } from "@App/hooks/useSitePages";
import { IBreadcrumbItem, Breadcrumbs } from "Components/breadcrumbs";
import {
  ISelectedDetailInfo,
  DetailTypes,
} from "Store/reducers/sitePages.reducer.types";

function filterItemsBaseOnDetails(items, selectedDetail: ISelectedDetailInfo) {
  if (selectedDetail.type === DetailTypes.main) {
    return items.filter((item) => item.value === DetailTypes.main);
  }

  if (selectedDetail.type === DetailTypes.section) {
    return items.filter((item) => item.value !== DetailTypes.card);
  }

  return items;
}

export const PageDetailsBreadcrumbs: FC = () => {
  const { selectedDetailInfo, selectSitePageDetail } = useSitePagesActions();
  const { type } = selectedDetailInfo;

  const breadcrumbs: IBreadcrumbItem[] = [
    {
      title: "Page Details",
      value: DetailTypes.main,
    },
    {
      title: "Section",
      value: DetailTypes.section,
    },
    {
      title: "Card",
      value:
        type === DetailTypes.parentCard
          ? DetailTypes.parentCard
          : DetailTypes.card,
    },
  ];

  const items = filterItemsBaseOnDetails(breadcrumbs, selectedDetailInfo);

  const handleBreadCrumbClick = (breadcrumbItem: IBreadcrumbItem) => () => {
    const { sectionIndex, webPartIndex, column } = selectedDetailInfo;
    const detailType = breadcrumbItem.value as DetailTypes;
    const isCard = isDetailCard(detailType);

    if (detailType === type) return;
    selectSitePageDetail({
      type: detailType,
      sectionIndex,
      ...(isCard && { webPartIndex, column }),
    });
  };

  return <Breadcrumbs items={items} onClick={handleBreadCrumbClick} />;
};
