import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import {
  HistoryAdaptedRouter,
  UuiContext,
  useUuiServices,
} from "@epam/uui-core";
import { Snackbar, Modals } from "@epam/uui-components";
import { ErrorHandler } from "@epam/promo";
import { App } from "./App";
import { getBaseName } from "SP/configure";
import store from "Store/store";
import { withAuth } from "./hoc/withAuth";

import "@epam/uui-components/styles.css";
import "@epam/uui/styles.css";
import "@epam/loveship/styles.css";
import "SCSS/index.scss";

const history = createBrowserHistory({ basename: getBaseName() });
const router = new HistoryAdaptedRouter(history);

const UuiEnhancedApp = () => {
  const { services } = useUuiServices({ router });

  return (
    <UuiContext.Provider value={services}>
      <ErrorHandler>
        <App />
        <Snackbar />
        <Modals />
      </ErrorHandler>
    </UuiContext.Provider>
  );
};

const RoutedApp = withAuth(() => {
  return (
    <Router history={history}>
      <Provider store={store}>
        <UuiEnhancedApp />
      </Provider>
    </Router>
  );
}) as any;

(async function renderApp() {
  ReactDOM.render(<RoutedApp />, document.getElementById("root"));
})();
