import {
  ISitePage,
  ISitePageDTO,
  ISitePageVersion,
  ISitePageVersionDTO,
  LibraryName,
} from "./sitePages.types";
import { parseJson } from "./sitePages.utils";
import { IItemUpdateResult } from "@pnp/sp/items";
import { SitePagesRepository } from "./sitePages.repository";

export class SitePagesService {
  repository = new SitePagesRepository();

  public async getSitePageByServerRelativeUrl(url: string): Promise<ISitePage> {
    const pageItem: ISitePageDTO =
      await this.repository.getSitePageByServerRelativeUrl(url);

    return this.mapSitePage(pageItem);
  }

  public async getSitePageVersions(pageId: number) {
    const versions: ISitePageVersionDTO[] =
      await this.repository.getSitePageVersions(pageId);
    return versions.map(this.mapSitePageVersion);
  }

  public async updatePage(
    id: number,
    data: ISitePage
  ): Promise<IItemUpdateResult> {
    const sitePage = this.reversMapSitePage(data);

    return await this.repository.updatePage(id, sitePage);
  }

  public async getById(id: number): Promise<ISitePage> {
    const pageItem = await this.repository.getById(id);
    return this.mapSitePage(pageItem);
  }

  public async checkIfUSerHasEditPermission(): Promise<boolean> {
    return await this.repository.checkIfUSerHasEditPermission();
  }

  public async checkOutSitePage(id: number): Promise<void> {
    return await this.repository.checkOutSitePage(id);
  }

  public async checkInSitePage(id: number, comment?: string): Promise<void> {
    return await this.repository.checkInSitePage(id, comment);
  }

  public async discardCheckOutSitePage(id: number): Promise<void> {
    return await this.repository.discardCheckOutSitePage(id);
  }

  private mapSitePageVersion = (
    version: ISitePageVersionDTO
  ): ISitePageVersion => {
    return {
      Editor: {
        email: version.Editor.Email,
        name: version.Editor.LookupValue,
        id: version.Editor.LookupId,
      },
      Modified: new Date(version.Modified),
      Title: version.Title,
      pageInfo: parseJson(version.pageInfo),
      VersionLabel: version.VersionLabel,
      CheckInComment: version.OData__x005f_CheckinComment,
    };
  };

  private reversMapSitePage = (page: ISitePage): Partial<ISitePageDTO> => {
    const updateData = {
      Title: page.Title,
      pageInfo: JSON.stringify(page.pageInfo),
    };

    return updateData;
  };

  private mapSitePage = (page: ISitePageDTO): ISitePage => {
    return {
      Author: {
        email: page.Author?.EMail,
        id: page.Author?.Id,
        name: page.Author?.Title,
      },
      Created: new Date(page.Created),
      Editor: {
        email: page.Editor?.EMail,
        id: page.Editor?.Id,
        name: page.Editor?.Title,
      },
      Id: page.Id,
      Modified: new Date(page.Modified),
      Title: page.Title,
      CheckoutUser: page.CheckoutUser && {
        email: page.CheckoutUser?.EMail,
        id: page.CheckoutUser?.Id,
        name: page.CheckoutUser?.Title,
      },
      fileInfo: {
        Length: page.File?.Length,
        Name: page.File?.Name,
        ServerRelativeUrl: page.File?.ServerRelativeUrl,
        FileType: page.File_x0020_Type,
        UniqueId: page.File?.UniqueId,
      },
      pageInfo: parseJson(page.pageInfo),
      redirectToPage: page.redirectToPage,
      sourceLibrary: LibraryName.pages,
    };
  };
}
