import { Text } from "@epam/loveship";
import { errorMessages } from "Helpers/constants";

export const errorImages = {
  [errorMessages.notAuthorized]:
    "https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/error-pages/error-403.svg",
  default:
    "https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/error-pages/error-500.svg",
};

export const ErrorPageComponent = ({ error }) => {
  return (
    <div className="error-page-content flex flex-col items-center justify-center">
      <div>
        <img
          className="error-image"
          src={errorImages[error] || errorImages.default}
          alt="Error"
        />
      </div>

      <div>
        <Text fontSize="24" lineHeight="30">
          {error}
        </Text>
      </div>
    </div>
  );
};
