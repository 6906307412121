import { spfi, SPBrowser } from "@pnp/sp";
import { graphfi, GraphBrowser } from "@pnp/graph";
import cache from "./cache";
import { getOnAuthQueryableHandler as getOnAuth } from "@App/libs/msal";

import "@pnp/sp/webs";
import "@pnp/graph/users";
import "@pnp/sp/sites";
import "@pnp/sp/folders";
import "@pnp/sp/taxonomy";
import "@pnp/sp/clientside-pages/web";
import "@pnp/sp/fields";
import "@pnp/sp/lists";
import "@pnp/sp/profiles";
import "@pnp/sp/site-users/web";
import "@pnp/graph/photos";
import { InjectHeaders } from "@pnp/queryable";
import { isSharepointHost } from "Helpers/constants";

export interface ISiteInfo {
  Id: string;
  Url: string;
  ServerRelativeUrl: string;
}

const sp = spfi(process.env.REACT_APP_BASE_URL).using(SPBrowser());
const graph = graphfi("https://graph.microsoft.com/v1.0").using(GraphBrowser());

const spSite = sp.site;
export const graphMe = graph.me;
export const spProfiles = sp.profiles;
export const spWeb = sp.web.using(
  InjectHeaders({
    "Cache-Control": "no-cache, no-store, must-revalidate",
  })
);

if (!isSharepointHost) {
  spSite.on.auth(getOnAuth());
  spWeb.on.auth(getOnAuth());
  spProfiles.on.auth(getOnAuth());
  graphMe.on.auth(
    getOnAuth([
      "https://graph.microsoft.com/User.Read",
      "https://graph.microsoft.com/profile",
    ])
  );
}

export async function configurePnP(): Promise<void> {
  try {
    const web = await spWeb();
    const site = await spSite.select("Id")();

    cache.add<ISiteInfo>(cache.Keys.siteInfo, {
      Id: site.Id,
      Url: web.Url,
      ServerRelativeUrl: web.ServerRelativeUrl,
    });
  } catch (error) {
    console.info("Error when getting site data:", error);
  }
}

export function getBaseName(): string {
  let baseName = "/";
  const indexPath = "index.aspx";
  const path = window.location.pathname.toLowerCase();

  if (path.includes(indexPath)) {
    const endIndex = path.indexOf(indexPath);
    baseName = `${path.substring(0, endIndex)}${indexPath}`;
  }

  return baseName;
}
