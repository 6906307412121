import React, { FC, Fragment, ReactNode } from "react";

import { ScrollBars } from "@epam/loveship";

import { Loader } from "Components/loader";
import { WidenNotification } from "Components/widen-notification";
import { withNaming } from "Helpers/bemClassName";
import { useSitePagesActions } from "@App/hooks/useSitePages";

import "./layout.scss";

interface ILayoutProps {
  children: ReactNode;
  loading?: boolean;
  isContentLoaded?: boolean;
}

export const Layout: FC<ILayoutProps> = ({ children, loading }) => {
  const cn = withNaming("app");
  const { isSitePageInEditMode, isSitePageInVersionHistoryMode } =
    useSitePagesActions();

  const Scroll = isSitePageInEditMode ? ScrollBars : Fragment;

  return (
    <div
      className={cn("", {
        "edit-mode": isSitePageInEditMode || isSitePageInVersionHistoryMode,
      })}
    >
      {isSitePageInEditMode && <WidenNotification />}
      <Scroll {...(isSitePageInEditMode && { cx: cn("scroll") })}>
        <div className={cn("wall")} />
        {loading && <Loader />}
        <div
          className={cn("container", {
            "edit-mode": isSitePageInEditMode || isSitePageInVersionHistoryMode,
          })}
        >
          {children}
        </div>
      </Scroll>
    </div>
  );
};
