import React, { FC } from "react";

import { FlexCell } from "@epam/loveship";

import { ISection } from "SP/sitePages/sitePages.types";

import { AddSectionButton } from "./sections-add-section";
import { isLastItem } from "Helpers/utils";
import { Section } from "./sections-section";

interface ISectionsProps {
  sections: ISection[];
  isEditMode: boolean;
}

export const Sections: FC<ISectionsProps> = ({ sections = [], isEditMode }) => {
  if (!sections?.length && !isEditMode) {
    return null;
  }

  if (!sections?.length && isEditMode) {
    return <AddSectionButton hideBefore position="prev" sectionIndex={0} />;
  }

  return (
    <FlexCell grow={2} cx="sections" rawProps={{ "data-testid": "sections" }}>
      {sections.map((section, sectionIndex) => {
        return (
          <Section
            key={sectionIndex}
            section={section}
            sectionIndex={sectionIndex}
            isLastSection={isLastItem(sections, sectionIndex)}
          />
        );
      })}
    </FlexCell>
  );
};
