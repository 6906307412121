import React, { FC, useLayoutEffect, useState } from "react";

import {
  Checkbox,
  LabeledInput,
  Text,
  TextInput,
  TextArea,
} from "@epam/loveship";

import { withNaming } from "Helpers/bemClassName";
import { CardKeys, usePageDetailsActions } from "@App/hooks/usePageDetails";
import {
  IRichTextEditorWebPart,
  IWebPart,
  WebPartTypes,
} from "SP/sitePages/sitePages.types";

import { IPageDetailsProps } from "./page-details.interface";
import { PageDetailsHeader } from "./page-details-header";

const getCardData = (card: IWebPart) => {
  if (card.type === WebPartTypes.RichTextEditorWebPart) {
    return card.data as IRichTextEditorWebPart;
  }
};

export const PageDetailsCard: FC<IPageDetailsProps> = ({ sitePageUrl }) => {
  const cn = withNaming("page-details");
  const [collapsible, setCollapsible] = useState(false);
  const [displayDividers, setDisplayDividers] = useState(false);
  const [showCurtain, setShowCurtain] = useState(false);
  const [isShowInputs, setIsShowInputs] = useState(false);

  const {
    card,
    title,
    onTitleChange,
    handleDeleteCard,
    handleUpdateSitePage,
    isAccordion,
  } = usePageDetailsActions(sitePageUrl);
  const [iconUrl, setIconUrl] = useState(card?.data?.iconUrl || "");
  const [btnUrl, setBtnUrl] = useState(card?.data?.btnUrl || "");

  const handleTitleChange = (value: string) => {
    onTitleChange(value);
    handleUpdateSitePage(value, CardKeys.accordionTitle);
  };

  const handleIconUrlChange = (value: string) => {
    setIconUrl(value);
    handleUpdateSitePage(value, CardKeys.iconUrl);
  };

  const handleBtnUrlChange = (value: string) => {
    setBtnUrl(value);
    handleUpdateSitePage(value, CardKeys.btnUrl);
  };

  const handleCollapseChange = (value: boolean) => {
    const newValue = value ? "accordion" : "card";
    setCollapsible(value);
    handleUpdateSitePage(newValue, CardKeys.surroundedBy);
  };

  const handleDisplayDividersChange = (value: boolean) => {
    setDisplayDividers(value);
    handleUpdateSitePage(value, CardKeys.displayDividers);
  };

  const handleShowCurtainChange = (value: boolean) => {
    setShowCurtain(value);
    handleUpdateSitePage(value, CardKeys.showCurtain);
  };

  useLayoutEffect(() => {
    if (card) {
      const cardData = getCardData(card);
      if (cardData.surroundedBy) {
        onTitleChange(cardData.accordionTitle);
        setCollapsible(isAccordion(cardData.surroundedBy));
        setIsShowInputs(true);
      }
      setDisplayDividers(cardData.displayDividers);
      setShowCurtain(cardData.showCurtain);
    }
  }, []);

  return (
    <div className={cn("wrapper")}>
      <PageDetailsHeader
        title="Card"
        sitePageUrl={sitePageUrl}
        onDelete={() => handleDeleteCard(CardKeys.accordionTitle)}
      />
      {isShowInputs && (
        <div className={cn("row", { card: true })}>
          <div className={cn("col")}>
            <Checkbox
              cx={cn("collapsible-checkbox")}
              label="Display card title"
              value={collapsible}
              onValueChange={handleCollapseChange}
            />
            {collapsible && (
              <LabeledInput
                isRequired
                size="none"
                label="Title"
                isInvalid={!title}
                validationMessage="This field is required!"
              >
                <TextInput
                  placeholder="Title"
                  value={title}
                  onValueChange={handleTitleChange}
                  isInvalid={!title}
                />
              </LabeledInput>
            )}
            <LabeledInput size="none" label="Icon URL">
              <TextArea
                autoSize
                placeholder="Add svg URL"
                value={iconUrl}
                onValueChange={handleIconUrlChange}
              />
            </LabeledInput>
            <LabeledInput size="none" label="Link Button URL">
              <TextArea
                autoSize
                placeholder="Add button URL"
                value={btnUrl}
                onValueChange={handleBtnUrlChange}
              />
            </LabeledInput>
            <Checkbox
              cx={cn("showCurtain-checkbox")}
              label="Collapse content when it’s too long"
              value={showCurtain}
              onValueChange={handleShowCurtainChange}
            />
            <Text cx={cn("info-text")} fontSize="12" color="night600">
              When content height exceeds 180px users will see only first 180px,
              the rest will be displayed after clicking “Show More” button
            </Text>
            <Checkbox
              cx={cn("dividers-checkbox")}
              label="Display dividers on borderless tables"
              value={displayDividers}
              onValueChange={handleDisplayDividersChange}
            />
            <Text cx={cn("info-text")} fontSize="12" color="night600">
              Every borderless table on the card will get gray 1px column
              dividers
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};
