import React, { FC, Fragment } from "react";

import { IPathKeys } from "Helpers/sitePageUpdater";
import { isLastItem } from "Helpers/utils";
import { useSitePagesActions } from "@App/hooks/useSitePages";
import { IWebPart } from "SP/sitePages/sitePages.types";

import { AddWebPartButton } from "./web-parts-add-web-part";
import { ISelectDetailProps, WebPart } from "./web-parts-web-part";
import { sitePageUrl } from "Helpers/constants";

export type ISectionDataColumn = "webParts" | "left" | "center" | "right";

export interface IWebPartsProps {
  webParts: IWebPart[];
  sectionIndex: number;
  column: ISectionDataColumn;
  hideAddBtns?: boolean;
}

export interface IWebPartItemProps {
  webPart: IWebPart;
  pathKeys: IPathKeys;
  selectDetailProps: ISelectDetailProps;
}

export const WebParts: FC<IWebPartsProps> = ({
  webParts,
  sectionIndex,
  column,
  hideAddBtns,
}) => {
  const { isSitePageInEditMode } = useSitePagesActions();
  const showAddBtns = isSitePageInEditMode && !hideAddBtns;

  const commonProps = {
    sitePageUrl,
    sectionIndex,
    column,
  };

  if (!webParts?.length && isSitePageInEditMode) {
    return (
      showAddBtns && (
        <AddWebPartButton {...commonProps} position="prev" webPartIndex={0} />
      )
    );
  }

  return (
    <>
      {webParts.map((webPart, webPartIndex) => (
        <Fragment key={`${sitePageUrl}_${webPartIndex}`}>
          {showAddBtns && (
            <AddWebPartButton
              {...commonProps}
              position="prev"
              webPartIndex={webPartIndex}
            />
          )}
          <WebPart
            webPart={webPart}
            selectDetailProps={{ sectionIndex, webPartIndex, column }}
            pathKeys={[
              sitePageUrl,
              "pageInfo",
              "sections",
              sectionIndex,
              "data",
              column,
              webPartIndex,
            ]}
          />
          {showAddBtns && isLastItem(webParts, webPartIndex) && (
            <AddWebPartButton
              {...commonProps}
              position="next"
              webPartIndex={webPartIndex}
            />
          )}
        </Fragment>
      ))}
    </>
  );
};
