export enum PageType {
  Parent = "Parent",
  Child = "Child",
}

// TODO: replace outdated this types with real data types start
export enum LibraryName {
  "all" = "All", // Only used for filter purposes
  "regulationsAndTemplates" = "regulationsAndTemplates", // Only used for search purposes
  "regulations" = "EPAM Regulations",
  "templates" = "EPAM Templates",
  "certificates" = "EPAM Certificates",
  "pages" = "Site Pages",
  "roles" = "Roles Registry",
}

export type RolesRegistryColumns =
  | "RoleName"
  | "RoleCategory"
  | "Group"
  | "Status";

export type RolesRegistryFilterColumns =
  | "RoleName"
  | "RoleCategory"
  | "Group"
  | "Status"
  | "PublishedDate"
  | "Area"
  | "Regulations";

export type DocumentsFilterColumns =
  | "Title"
  | "SubjectDoc"
  | "DocumentArea"
  | "OrganizationalUnit"
  | "ApprovalDate";

export enum SectionTypes {
  "RowsLayout" = "RowsLayout",
  "OneColumnLayout" = "OneColumnLayout",
  "TwoColumnsLayout" = "TwoColumnsLayout",
  "ThreeColumnsLayout" = "ThreeColumnsLayout",
  "OneThirdLeftLayout" = "OneThirdLeftLayout",
  "OneThirdRightLayout" = "OneThirdRightLayout",
}

export enum WebPartTypes {
  "RichTextEditorWebPart" = "RichTextEditorWebPart",
  "ParentRichTextEditorWebPart" = "ParentRichTextEditorWebPart",
}

export type CertificatesFilterColumns =
  | "Title"
  | "ValidFrom"
  | "ValidUntil"
  | "Standard"
  | "LocationCity"
  | "LocationCountry"
  | "IssueDate"
  | "CertificationBody";

export type DocumentsColumns =
  | "Document"
  | "EPAMDocumentID"
  | "DocumentArea"
  | "LocationCountry"
  | "OrganizationalUnit"
  | "ApprovalDate";

export type CertificatesColumns =
  | "Document"
  | "EPAMDocumentID"
  | "Standard"
  | "CertificationBody"
  | "LocationCountry"
  | "LocationCity"
  | "IssueDate"
  | "ValidFrom"
  | "ValidUntil";

// TODO: replace outdated this types with real data types end

export interface ISitePage {
  readonly Id: number;
  Title: string;
  readonly Created: Date;
  readonly Author: IUser;
  readonly Modified: Date;
  readonly Editor: IUser;
  readonly CheckoutUser: IUser;
  readonly fileInfo: IFileInfo;
  readonly redirectToPage: string;
  pageInfo: IPageInfo;
  readonly sourceLibrary: LibraryName;
}

export interface IPageInfo {
  attachedFiles: IAttachedFiles;
  showSubscribeButton: boolean;
  sections?: ISection[];
  description?: string;
}

export interface ISection {
  type: SectionTypes;
  description?: string;
  iconUrl?: string;
  data:
    | IOneColumnLayout
    | ITwoColumnsLayout
    | IThreeColumnsLayout
    | IOneThirdLeftLayout
    | IOneThirdRightLayout;
}

export interface IOneColumnLayout {
  webParts: IWebPart[];
}
export interface ITwoColumnsLayout {
  left: IWebPart[];
  right: IWebPart[];
}
export interface IThreeColumnsLayout extends ITwoColumnsLayout {
  center: IWebPart[];
}
export interface IOneThirdLeftLayout {
  left: IWebPart[];
  right: IWebPart[];
}
export interface IOneThirdRightLayout {
  left: IWebPart[];
  right: IWebPart[];
}

export interface IWebPart {
  type: WebPartTypes;
  data: IRichTextEditorWebPart | IParentRichTextEditorWebPart;
}

export interface IDoubleListData {
  documents: ISingleListData;
  certificates: ISingleListData;
}

export interface IRichTextEditorWebPart {
  markup: string;
  surroundedBy: "accordion" | "card";
  accordionTitle: string;
  displayDividers?: boolean;
  showCurtain?: boolean;
  iconUrl?: string;
  btnUrl?: string;
}

export interface IParentRichTextEditorWebPart {
  markup: string;
  webPartTitle: string;
  webPartTitleLink?: string;
  webPartLinkColor: string;
  iconUrl?: string;
  btnUrl?: string;
}

export interface ISingleListData {
  list: LibraryName;
  filterPaneItems: ISingleListFilterItem[];
  columns: ISingleListColumn[];
}

export interface ISingleListFilterItem extends IVisibleProp {
  prop: DocumentsFilterColumns | CertificatesFilterColumns;
}
export interface ISingleListColumn extends IVisibleProp {
  prop: DocumentsColumns | CertificatesColumns;
}

export interface IRolesRegistryListData {
  filterPaneItems: IRolesRegistryListFilterItem[];
  columns: IRolesRegistryColumn[];
}

export interface IRolesRegistryListFilterItem extends IVisibleProp {
  prop: RolesRegistryFilterColumns;
}

export interface IRolesRegistryColumn extends IVisibleProp {
  prop: RolesRegistryColumns;
}

export interface IVisibleProp {
  visible: boolean;
}

export interface IUser {
  email: string;
  name: string;
  id: number;
  jobTitle?: string;
  photo?: string;
}

export interface IFileInfo {
  readonly Length: number;
  readonly Name: string;
  readonly ServerRelativeUrl: string;
  readonly FileType: string;
  readonly UniqueId: string;
}

export interface IAttachedFiles {
  library: LibraryName;
  items: number[];
}

export interface ILibraryFolder {
  Name: string;
  ServerRelativeUrl: string;
  Folders: ILibraryFolder[];
}

export interface IResourcePath {
  DecodedUrl: string;
  __metadata: {
    type: "SP.ResourcePath";
  };
}

export interface IFolderInfo {
  readonly "odata.id": string;
  Exists: boolean;
  IsWOPIEnabled: boolean;
  ItemCount: number;
  Name: string;
  ProgID: string | null;
  ServerRelativeUrl: string;
  ServerRelativePath: IResourcePath;
  TimeCreated: string;
  TimeLastModified: string;
  UniqueId: string;
  WelcomePage: string;
}

export interface INewSitePageInfo {
  folderServerRelativeUrl: string;
  pageTitle: string;
  pageType: PageType;
}

export interface ISitePageDTO {
  Id: number;
  Title: string;
  Created: string;
  Author: ISpUser;
  Modified: string;
  Editor: ISpUser;
  CheckoutUser: ISpUser;
  File: IFileInfo;
  pageInfo: string;
  PageTextContent: string;
  File_x0020_Type: string;
  redirectToPage: string;
  VersionLabel: string;
}

export interface ISpUser {
  readonly EMail: string;
  readonly Id: number;
  readonly Title: string;
}

export interface IUnique {
  UniqueId: string;
}

//TODO: define this type
export type IAttachedFile = IUnique & Record<string, any>;

export interface ISitePageVersion {
  readonly Title: string;
  readonly Modified: Date;
  readonly Editor: IUser;
  readonly pageInfo: IPageInfo;
  readonly VersionLabel: string;
  readonly CheckInComment: string;
}

export interface IUserLookupField {
  LookupId: number;
  LookupValue: string;
  Email: string;
}

export interface ISitePageVersionDTO {
  readonly Title: string;
  readonly Modified: string;
  readonly Editor: IUserLookupField;
  readonly pageInfo: string;
  readonly VersionLabel: string;
  readonly OData__x005f_CheckinComment: string;
}
