import * as React from "react";
import logo from "./icons/logo.svg";
import {
  MainMenuAvatar,
  FlexSpacer,
  MainMenu,
  MainMenuCustomElement,
  Dropdown,
  DropdownMenuBody,
  DropdownMenuButton,
} from "@epam/loveship";
import { useUsersData } from "./hooks/useUsers";
import { msalInstance } from "./libs/msal";

const handleLogout = () => {
  msalInstance.clearCache();
  msalInstance.logoutRedirect();
};

export const AppHeader = () => {
  const { currentUser } = useUsersData();

  return (
    <MainMenu appLogoUrl={logo}>
      <FlexSpacer priority={100500} />
      <MainMenuCustomElement priority={10} estimatedWidth={78}>
        <Dropdown
          renderTarget={(props) => (
            <MainMenuAvatar
              avatarUrl={currentUser?.photo}
              isDropdown
              {...props}
            />
          )}
          renderBody={() => (
            <DropdownMenuBody cx="dropdown dropdown--no-icons">
              <DropdownMenuButton caption="Log out" onClick={handleLogout} />
            </DropdownMenuBody>
          )}
          closeOnMouseLeave="boundary"
          placement="bottom-start"
        />
      </MainMenuCustomElement>
      <div id="global_menu_toggle" data-testid="global-menu">
        <img
          src="https://menu.epam.com/content/images/menu_button.svg"
          onError={() =>
            (document.getElementById("global_menu_toggle").style.display =
              "none")
          }
        />
      </div>
    </MainMenu>
  );
};
