import _get from "lodash/get";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { LabeledInput, TextArea } from "@epam/loveship";

import { LayoutSelect } from "Components/layout-select";
import { withNaming } from "Helpers/bemClassName";
import {
  useSitePagesActions,
  useSitePagesMethods,
} from "@App/hooks/useSitePages";

import { IPageDetailsProps } from "./page-details.interface";
import { PageDetailsHeader } from "./page-details-header";
import { IRootReducerState } from "Store/rootReducer";

export const PageDetailsSection: FC<IPageDetailsProps> = ({ sitePageUrl }) => {
  const cn = withNaming("page-details");
  const [layout, setLayout] = useState("");
  const {
    selectedDetailInfo: { sectionIndex },
  } = useSitePagesActions();
  const { updateSitePage } = useSitePagesMethods();

  const pathKeys = ["pageInfo", "sections", sectionIndex];
  const sectionLayout = useSelector<IRootReducerState, string>((state) =>
    _get(
      state.sitePages.sitePages[sitePageUrl],
      [...pathKeys, "type"].join("."),
      null
    )
  );
  const sectionDescription = useSelector<IRootReducerState, string>((state) =>
    _get(
      state.sitePages.sitePages[sitePageUrl],
      [...pathKeys, "description"].join("."),
      null
    )
  );

  const handleDescriptionChange = (description: string) => {
    updateSitePage([sitePageUrl, ...pathKeys, "description"], description);
  };

  const handleLayoutChange = (type: string) => () => {
    setLayout(type);
    updateSitePage([sitePageUrl, ...pathKeys, "type"], type);
  };

  useEffect(() => {
    if (sectionLayout) {
      setLayout(sectionLayout);
    }
  }, []);

  return (
    <div className={cn("wrapper")}>
      <PageDetailsHeader title="Section" sitePageUrl={sitePageUrl} />
      <div className={cn("row")}>
        <LabeledInput size="none" label="Section Description">
          <TextArea
            autoSize
            placeholder="Add description"
            value={sectionDescription || ""}
            onValueChange={handleDescriptionChange}
          />
        </LabeledInput>
      </div>
      <div className={cn("row", { card: true })}>
        <LabeledInput isRequired size="none" label="Layout">
          <LayoutSelect selectedLayout={layout} onChange={handleLayoutChange} />
        </LabeledInput>
      </div>
    </div>
  );
};
