import React, { FC } from "react";

import { ReactComponent as clearIcon } from "@epam/assets/icons/common/content-clear-18.svg";
import { LinkButton, Text } from "@epam/loveship";

import { ITooltipContentProps } from "Components/tooltip/tooltip";
import { withNaming } from "Helpers/bemClassName";
import { IVoidCb } from "Helpers/helpers.types";

export const REPUBLISH_ACTION_TEXT = "Can't be published while page is editing";
export const UNABLE_EDIT_TEXT = "You can’t edit this page";

interface IEditedByProps extends ITooltipContentProps {
  username: string;
  onDiscard: IVoidCb;
  isRepublishAction?: boolean;
}

export const EditedBy: FC<IEditedByProps> = ({
  onDiscard,
  username,
  onTooltipClose,
  isRepublishAction,
}) => {
  const cn = withNaming("page-ribbon");

  const handelDiscard = (e) => {
    onTooltipClose(e);
    onDiscard();
  };

  return (
    <div className={cn("edited-by")}>
      <Text
        cx={cn("edited-by-text")}
        color="night900"
        fontStyle="normal"
        fontWeight="600"
        fontSize="14"
        lineHeight="18"
      >
        {isRepublishAction ? REPUBLISH_ACTION_TEXT : UNABLE_EDIT_TEXT}
      </Text>
      <Text
        cx={cn("edited-by-text")}
        color="night900"
        fontSize="12"
        lineHeight="18"
      >
        The page will be available for{" "}
        {isRepublishAction ? "republishing" : "editing"} after {username}{" "}
        completes editing this page.
      </Text>
      <Text
        cx={cn("edited-by-text")}
        color="night900"
        fontSize="12"
        lineHeight="18"
      >
        As a site owner, if you’re confident {username}’s changes are not
        needed, you can discard them now.
      </Text>
      <LinkButton
        size="30"
        color="sky"
        caption="Discard Changes"
        icon={clearIcon}
        onClick={handelDiscard}
      />
    </div>
  );
};
