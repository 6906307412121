import React, { FC } from "react";

import { Text } from "@epam/loveship";

import { withNaming } from "Helpers/bemClassName";
import { SectionTypes } from "SP/sitePages/sitePages.types";

import "./layout-select.scss";

interface ILayoutSelectProps {
  onChange: (type: string, columnsCount: 0 | 1 | 2 | 3) => (e: any) => void;
  selectedLayout: string;
  className?: string;
  showTitle?: boolean;
}

interface ILayoutOptionProps
  extends Pick<ILayoutSelectProps, "onChange" | "showTitle"> {
  type: string;
  columns: 0 | 1 | 2 | 3;
  selected: boolean;
  title: string;
  cn: (b: any, e?: any, m?: any) => string;
}

interface ILayoutOptions {
  type: SectionTypes;
  title: string;
  columns: 0 | 1 | 2 | 3;
}

export const layoutOptions: ILayoutOptions[] = [
  { type: SectionTypes.RowsLayout, title: "Adaptive Rows", columns: 0 },
  { type: SectionTypes.OneColumnLayout, title: "One Column", columns: 1 },
  { type: SectionTypes.TwoColumnsLayout, title: "Two Columns", columns: 2 },
  {
    type: SectionTypes.OneThirdLeftLayout,
    title: "One-third Left",
    columns: 2,
  },
  {
    type: SectionTypes.OneThirdRightLayout,
    title: "One-third Right",
    columns: 2,
  },
  { type: SectionTypes.ThreeColumnsLayout, title: "Three Columns", columns: 3 },
];

const LayoutOption: FC<ILayoutOptionProps> = ({
  cn,
  type,
  title,
  showTitle,
  columns,
  selected,
  onChange,
}) => (
  <div
    className={cn("layout-option", { [type]: true, selected }, [
      "flex flex-col justify-center items-center cursor-pointer",
    ])}
    onClick={onChange(type, columns)}
    data-testid="layout-option"
    {...(!showTitle && { title })}
  >
    {columns ? (
      <div className={cn("columns", ["flex"])}>
        {Array.from(Array(columns).keys()).map((column) => (
          <span className="column" key={column}></span>
        ))}
      </div>
    ) : (
      <div className={cn("columns", ["flex flex-col"])}>
        <span className="row" key={1}></span>
        <span className="row" key={2}></span>
      </div>
    )}

    {showTitle && (
      <Text
        cx={cn("text")}
        size="none"
        color="night900"
        fontSize="14"
        lineHeight="18"
      >
        {title}
      </Text>
    )}
  </div>
);

export const LayoutSelect: FC<ILayoutSelectProps> = ({
  className,
  onChange,
  selectedLayout,
  showTitle,
}) => {
  const cn = withNaming(className ?? "layout-select");

  return (
    <div className={cn("", ["flex"])}>
      {layoutOptions.map(({ type, title, columns }) => (
        <LayoutOption
          key={type}
          cn={cn}
          type={type}
          title={title}
          columns={columns}
          showTitle={showTitle}
          selected={type === selectedLayout}
          onChange={onChange}
        />
      ))}
    </div>
  );
};
