import React, { FC } from "react";
import { withNaming } from "Helpers/bemClassName";
import { Text } from "@epam/loveship";

import "./section-description.scss";

interface IPageDescriptionProps {
  text: string;
}

export const SectionDescription: FC<IPageDescriptionProps> = ({ text }) => {
  const cn = withNaming("page-title");

  return (
    <div className={cn("", ["flex items-end"])}>
      <div className={cn("text")}>
        <Text
          color="night800"
          size="24"
          font="sans-semibold"
          fontSize="24"
          lineHeight="30"
          fontWeight="600"
        >
          {text}
        </Text>
      </div>
    </div>
  );
};
