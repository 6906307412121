import { isSharepointHost } from "Helpers/constants";
import { spWeb } from "SP/configure";
import { useEffect, useState } from "react";

const ImageComponent = ({
  src,
  style,
}: {
  src: string;
  style: React.CSSProperties;
}) => {
  const [imageSrc, setImageSrc] = useState(src);

  useEffect(() => {
    async function downloadImage(src) {
      if (!src || isSharepointHost) return;
      try {
        const buffer = await spWeb.getFileByUrl(src).getBuffer();
        const blob = new Blob([buffer], {
          type: "image/svg+xml",
        });

        setImageSrc(URL.createObjectURL(blob));
      } catch (e) {
        console.log("Error ocured when getting image", e);
      }
    }
    downloadImage(src);
  }, [src]);

  return <img style={style} src={imageSrc} alt="description" />;
};

export default ImageComponent;
