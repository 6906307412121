import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { AppHeader } from "./AppHeader";
import { Text } from "@epam/loveship";
import { DynamicSitePage } from "Pages/DynamicSitePage";
import { useUsersMethods } from "./hooks/useUsers";
import { useNotification } from "./hooks/useShowNotification";

import css from "./App.module.scss";

export const App = () => {
  const { getCurrentUser } = useUsersMethods();
  useNotification();

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <div className={css.app}>
      <Route component={AppHeader} />
      <main>
        <Switch>
          <Route path="/" component={DynamicSitePage} />
        </Switch>
      </main>
      <footer>
        <Text
          color="night700"
          size="24"
          font="sans-semibold"
          fontSize="14"
          lineHeight="18"
          fontWeight="400"
        >
          Revenue Digital Platform © 2024 EPAM Systems
        </Text>
      </footer>
    </div>
  );
};
