import * as React from "react";

import { Loader } from "Components/loader";
import { configurePnP } from "SP/configure";
import { msalInstance, interactions } from "@App/libs/msal";
import { isSharepointHost } from "Helpers/constants";

interface IState {
  hasError: boolean;
  errorMessage: string;
  authenticated: boolean;
}

export function withAuth<TOriginalProps>(
  WrappedComponent: React.FC<TOriginalProps>
): React.ComponentClass<TOriginalProps> {
  return class Auth extends React.Component<TOriginalProps, IState> {
    private callbackId: string;

    constructor(props: TOriginalProps) {
      super(props);

      this.state = {
        hasError: false,
        errorMessage: null,
        authenticated: false,
      };
    }

    componentDidMount() {
      if (isSharepointHost) {
        this.handleAuthenticated();
        return;
      }

      msalInstance
        .initialize()
        .then(() => {
          this.callbackId = msalInstance.addEventCallback((message) => {
            interactions.updateState(
              message,
              interactions.MsalProviderActionType.EVENT
            );
          });
        })
        .then(() => {
          this.handleLogin();
        });
    }

    componentWillUnmount(): void {
      if (this.callbackId) {
        msalInstance.removeEventCallback(this.callbackId);
      }
    }

    handleLogin() {
      msalInstance
        .handleRedirectPromise()
        .then((tokenResponse) => {
          const accounts = msalInstance.getAllAccounts();
          if (!tokenResponse) {
            if (accounts.length) {
              interactions.setInteractionsAccount(accounts[0]);
              this.handleAuthenticated();
            } else {
              msalInstance.loginRedirect();
            }
          } else {
            const account = accounts.find(
              (account) =>
                account.username ===
                (tokenResponse.idTokenClaims as any).preferred_username
            );
            interactions.setInteractionsAccount(account);
            this.handleAuthenticated();
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          /*
           * If handleRedirectPromise returns a cached promise the necessary events may not be fired
           * This is a fallback to prevent inProgress from getting stuck in 'startup'
           */
          interactions.updateState(
            null,
            interactions.MsalProviderActionType.UNBLOCK_INPROGRESS
          );
        });
    }

    handleAuthenticated() {
      this.setState({
        authenticated: true,
      });
      configurePnP();
    }

    handleError(error: any) {
      this.setState({
        hasError: true,
        errorMessage: error.statusText
          ? error.statusText.toString()
          : "Failed to authenticate the current user.",
      });
    }

    render(): JSX.Element {
      if (this.state.authenticated) {
        return <WrappedComponent {...this.props} />;
      }

      if (this.state.hasError) {
        return (
          <div className="app">
            <div className="app__container flex items-center justify-center">
              <code>{this.state.errorMessage}</code>
            </div>
          </div>
        );
      }

      return <Loader />;
    }
  };
}
