import { enableMapSet } from "immer";
import { combineReducers } from "@reduxjs/toolkit";
import {
  ISitePagesState,
  sitePagesReducer,
} from "./reducers/sitePages.reducer";

enableMapSet();

export interface IRootReducerState {
  sitePages: ISitePagesState;
}

export const rootReducer = combineReducers<IRootReducerState>({
  sitePages: sitePagesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
