import React, { FC } from "react";

import { ReactComponent as closeIconSmall } from "@epam/assets/icons/common/navigation-close_bold-12.svg";
import { ReactComponent as closeIcon } from "@epam/assets/icons/common/navigation-close-24.svg";
import { IconButton, LinkButton, Text } from "@epam/loveship";

import { withNaming } from "Helpers/bemClassName";
import { SitePageUpdaterKeys } from "Helpers/sitePageUpdater";
import { isDetailCard } from "Helpers/utils";
import {
  useSitePagesActions,
  useSitePagesMethods,
} from "@App/hooks/useSitePages";

import { IPageDetailsHeaderProps } from "./page-details.interface";
import { DetailTypes } from "Store/reducers/sitePages.reducer.types";

const deleteNames = {
  Section: "Section",
  Card: "Component",
};

export const PageDetailsHeader: FC<IPageDetailsHeaderProps> = ({
  title,
  sitePageUrl,
  onDelete,
}) => {
  const cn = withNaming("page-details");
  const { selectedDetailInfo, selectSitePageDetail } = useSitePagesActions();
  const { updateSitePage } = useSitePagesMethods();

  const handleClose = () => {
    selectSitePageDetail({ type: DetailTypes.main });
  };

  const handleDelete = () => {
    const { type, sectionIndex, webPartIndex, column } = selectedDetailInfo;
    let pathKeys = [sitePageUrl, "pageInfo", "sections", sectionIndex];

    if (isDetailCard(type)) {
      pathKeys = pathKeys.concat(["data", column, webPartIndex]);
    }

    updateSitePage(pathKeys, SitePageUpdaterKeys.DELETE);
    onDelete?.();
    handleClose();
  };

  return (
    <div className={cn("row")}>
      <div className={cn("col")}>
        <Text
          size="24"
          color="night800"
          font="sans-semibold"
          fontSize="18"
          lineHeight="24"
        >
          {title}
        </Text>
        <LinkButton
          icon={closeIconSmall}
          cx={cn("delete-button")}
          size="none"
          color="night600"
          caption={`Delete ${deleteNames[title]}`}
          onClick={handleDelete}
        />
      </div>
      <IconButton icon={closeIcon} onClick={handleClose} />
    </div>
  );
};
