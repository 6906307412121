import _cloneDeep from "lodash/cloneDeep";
import { UsersService } from "SP/currentUser/users.service";
import { SitePagesService } from "SP/sitePages/sitePages.service";
import {
  ISitePage,
  ISitePageVersion,
  IUser,
  LibraryName,
} from "SP/sitePages/sitePages.types";
import {
  SitePagesActionsTypes,
  IGetSitePageByUrlRequestAction,
  getSitePageByUrlFailure,
  getSitePageByUrlSuccess,
  IGetCurrentUserRequestAction,
  getCurrentUserFailure,
  getCurrentUserSuccess,
  IRepublishSitePageRequestAction,
  republishSitePageFailure,
  republishSitePageSuccess,
  ICheckOutSitePageRequestAction,
  checkOutSitePageFailure,
  checkOutSitePageSuccess,
  IDiscardCheckOutSitePageRequestAction,
  ISaveAsDraftSitePageRequestAction,
  discardCheckOutSitePageFailure,
  discardCheckOutSitePageSuccess,
  saveAsDraftSitePageFailure,
  saveAsDraftSitePageSuccess,
  IGetSitePageVersionsAction,
  getSitePageVersionsSuccess,
  ISetSitePageVersionAction,
  setSitePageVersionFailure,
  setSitePageVersionSuccess,
  setSitePageError,
  getSitePageVersionsFailure,
  setCriticalError,
} from "Store/actions/sitePages.actions";
import { IRootReducerState } from "Store/rootReducer";
import {
  call,
  put,
  select,
  takeEvery,
  fork,
  ForkEffect,
} from "redux-saga/effects";
import { errorMessages } from "Helpers/constants";

const sitePagesService = new SitePagesService();
const usersService = new UsersService();

const sitePageSelector = (url: string) => (state: IRootReducerState) =>
  state.sitePages.sitePages[url];
const currentUserSelector = (state: IRootReducerState) =>
  state.sitePages.currentUser;
const sitePageVersionsSelector = (pageId) => (state: IRootReducerState) =>
  state.sitePages.versions[pageId];

export function* getSitePageByUrl(action: IGetSitePageByUrlRequestAction) {
  try {
    let sitePage: ISitePage = yield select(sitePageSelector(action.url));

    if (!sitePage) {
      sitePage = yield call(
        [sitePagesService, "getSitePageByServerRelativeUrl"],
        action.url
      );
    }

    if (sitePage.pageInfo === null) {
      sitePage.pageInfo = {
        attachedFiles: { library: LibraryName.regulations, items: [] },
        showSubscribeButton: false,
        sections: [],
      };
    }

    yield put(getSitePageByUrlSuccess({ url: action.url, sitePage }));
  } catch (e: any) {
    if (e.status === 404) {
      yield put(setSitePageError(errorMessages.pageNotFound));
    }
    yield put(getSitePageByUrlFailure(e));
  }
}

export function* getCurrentUser(action: IGetCurrentUserRequestAction) {
  try {
    const user = yield select(currentUserSelector);
    if (user) {
      action.cb?.(user);
      return;
    }

    const currentUser: IUser = yield call([usersService, "getCurrentUser"]);
    const canEditSitePage = yield call([
      sitePagesService,
      "checkIfUSerHasEditPermission",
    ]);

    yield put(getCurrentUserSuccess({ ...currentUser, canEditSitePage }));

    action.cb?.(currentUser);
  } catch (e: any) {
    if (e.status === 403 || e.status === 401) {
      yield put(setCriticalError(errorMessages.notAuthorized));
    }
    yield put(getCurrentUserFailure(e));
  }
}

export function* republishSitePage(action: IRepublishSitePageRequestAction) {
  try {
    let sitePage: ISitePage = yield select(
      sitePageSelector(action.sitePageUrl)
    );
    yield call([sitePagesService, "updatePage"], action.sitePageId, sitePage);
    yield call([sitePagesService, "checkInSitePage"], action.sitePageId);

    sitePage = yield call([sitePagesService, "getById"], action.sitePageId);

    yield put(republishSitePageSuccess(action.sitePageUrl, sitePage));
  } catch (e) {
    yield put(republishSitePageFailure(e));
    throw e;
  }
}

export function* checkOutSitePage(action: ICheckOutSitePageRequestAction) {
  try {
    yield call([sitePagesService, "checkOutSitePage"], action.sitePageId);

    if (action.cb) {
      action.cb();
    }

    yield put(checkOutSitePageSuccess(action.sitePageUrl, action.currentUser));
  } catch {
    yield put(
      checkOutSitePageFailure(
        "The page is checked out by another user and can't be edited"
      )
    );
  }
}

export function* discardCheckOutSitePage(
  action: IDiscardCheckOutSitePageRequestAction
) {
  try {
    yield call(
      [sitePagesService, "discardCheckOutSitePage"],
      action.sitePageId
    );

    const sitePage: ISitePage = yield call(
      [sitePagesService, "getById"],
      action.sitePageId
    );

    yield put(discardCheckOutSitePageSuccess(action.sitePageUrl, sitePage));
  } catch (e) {
    yield put(discardCheckOutSitePageFailure(e));
    yield put(setSitePageError(errorMessages.noCheckedInVersion));
  }
}

export function* saveAsDraftSitePage(
  action: ISaveAsDraftSitePageRequestAction
) {
  try {
    let sitePage: ISitePage = yield select(
      sitePageSelector(action.sitePageUrl)
    );

    yield call([sitePagesService, "updatePage"], action.sitePageId, sitePage);
    sitePage = yield call([sitePagesService, "getById"], action.sitePageId);

    yield put(saveAsDraftSitePageSuccess(action.sitePageUrl, sitePage));
  } catch (e) {
    yield put(saveAsDraftSitePageFailure(e));
    throw e;
  }
}

export function* getSitePageVersions(action: IGetSitePageVersionsAction) {
  try {
    const sitePage: ISitePage = yield select(sitePageSelector(action.pageUrl));
    const versions = yield select(sitePageVersionsSelector(action.pageUrl));

    let items: ISitePageVersion[] = versions?.items;

    if (!items) {
      items = yield call(
        [sitePagesService, "getSitePageVersions"],
        sitePage.Id
      );
    }

    yield put(getSitePageVersionsSuccess(action.pageUrl, items));
  } catch (e) {
    yield put(getSitePageVersionsFailure(e));
    throw e;
  }
}

export function* setSitePageVersion(action: ISetSitePageVersionAction) {
  try {
    const sitePage = yield select(sitePageSelector(action.pageUrl));
    const clonedSitePage: ISitePage = _cloneDeep(sitePage);
    clonedSitePage.pageInfo = action.version.pageInfo;

    yield call([sitePagesService, "checkOutSitePage"], clonedSitePage.Id);
    yield call(
      [sitePagesService, "updatePage"],
      clonedSitePage.Id,
      clonedSitePage
    );
    yield call([sitePagesService, "checkInSitePage"], clonedSitePage.Id);

    yield put(setSitePageVersionSuccess(action.pageUrl, clonedSitePage));
  } catch (e) {
    yield put(setSitePageVersionFailure(e));
    throw e;
  }
}

export function* watchSetSitePageVersion() {
  yield takeEvery(
    SitePagesActionsTypes.SET_SITE_PAGE_VERSION,
    setSitePageVersion
  );
}

export function* watchGetSitePageVersions() {
  yield takeEvery(
    SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS,
    getSitePageVersions
  );
}

export function* watchGetSitePageByUrl() {
  yield takeEvery(SitePagesActionsTypes.GET_SITE_PAGE_BY_URL, getSitePageByUrl);
}

export function* watchGetCurrentUser() {
  yield takeEvery(
    SitePagesActionsTypes.GET_CURRENT_USER_REQUEST,
    getCurrentUser
  );
}

export function* watchRepublishSitePage() {
  yield takeEvery(SitePagesActionsTypes.REPUBLISH_SITE_PAGE, republishSitePage);
}

export function* watchCheckOutSitePage() {
  yield takeEvery(SitePagesActionsTypes.CHECK_OUT_SITE_PAGE, checkOutSitePage);
}

export function* watchDiscardCheckOutSitePage() {
  yield takeEvery(
    SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE,
    discardCheckOutSitePage
  );
}

export function* watchSaveAsDraftSitePage() {
  yield takeEvery(
    SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE,
    saveAsDraftSitePage
  );
}

export default function* sitePagesSagas(): Iterator<ForkEffect> {
  yield fork(watchGetSitePageByUrl);
  yield fork(watchGetCurrentUser);
  yield fork(watchRepublishSitePage);
  yield fork(watchCheckOutSitePage);
  yield fork(watchDiscardCheckOutSitePage);
  yield fork(watchSaveAsDraftSitePage);
  yield fork(watchGetSitePageVersions);
  yield fork(watchSetSitePageVersion);
}
