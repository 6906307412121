import { IPathKeys } from "Helpers/sitePageUpdater";
import { ISitePage, ISitePageVersion } from "SP/sitePages/sitePages.types";
import {
  getSitePageByUrl,
  updateSitePage,
  selectSitePageDetail,
  setSitePageInValidProperty,
  clearSitePageInValid,
  setSitePageEditMode,
  ICheckoutParams,
  checkOutSitePage,
  IDiscardCheckoutParams,
  discardCheckOutSitePage,
  republishSitePage,
  saveAsDraftSitePage,
  setSitePageVersion,
  getSitePageVersions,
  setSitePageVersionHistoryMode,
  selectSitePageVersion,
} from "Store/actions/sitePages.actions";
import { ISitePageVersions } from "Store/reducers/sitePages.reducer";
import { ISelectedDetailInfo } from "Store/reducers/sitePages.reducer.types";
import { IRootReducerState } from "Store/rootReducer";
import { useDispatch, useSelector } from "react-redux";

export const useSitePagesData = (url?: string) => {
  const loading = useSelector<IRootReducerState, boolean>(
    (state) => state.sitePages.pageLoading
  );

  const sitePage = useSelector<IRootReducerState, ISitePage>(
    (state) => state.sitePages.sitePages[url || ""]
  );
  const editPageBackup = useSelector<IRootReducerState, ISitePage>(
    (state) => state.sitePages.editPageBackup
  );

  const criticalError = useSelector<IRootReducerState, string>(
    (state) => state.sitePages.criticalError
  );

  return {
    loading,
    sitePage,
    editPageBackup,
    criticalError,
  };
};

export const useSitePagesActions = () => {
  const dispatch = useDispatch();
  const selectedDetailInfo = useSelector<
    IRootReducerState,
    ISelectedDetailInfo
  >((state) => state.sitePages.selectedDetailInfo);

  const isSitePageInVersionHistoryMode = useSelector<
    IRootReducerState,
    boolean
  >((state) => state.sitePages.isSitePageInVersionHistoryMode);

  const isSitePageInEditMode = useSelector<IRootReducerState, boolean>(
    (state) => state.sitePages.isSitePageInEditMode
  );

  const handleSelectSitePageDetail = (detailInfo: ISelectedDetailInfo) =>
    dispatch(selectSitePageDetail(detailInfo));

  const sitePageInValidProperties = useSelector<IRootReducerState, Set<string>>(
    (state) => state.sitePages.sitePageInValidProperties
  );

  const handleSetSitePageInValidProperty = (
    toggle: boolean,
    property: string
  ) => {
    if (
      (toggle && sitePageInValidProperties.has(property)) ||
      (!toggle && !sitePageInValidProperties.has(property))
    ) {
      return;
    }
    return dispatch(setSitePageInValidProperty(toggle, property));
  };

  const handleClearSitePageInValid = () => dispatch(clearSitePageInValid());

  const handleSetSitePageEditMode = (toggle: boolean, sitePageUrl: string) => {
    return dispatch(setSitePageEditMode(toggle, sitePageUrl));
  };

  const handleGetSitePageVersions = (pageUrl: string) =>
    dispatch(getSitePageVersions(pageUrl));

  const handleSetSitePageVersion = (
    pageUrl: string,
    version: ISitePageVersion
  ) => dispatch(setSitePageVersion(pageUrl, version));

  const handleSetSitePageVersionHistoryMode = (
    pageUrl: string,
    toggle: boolean
  ) => dispatch(setSitePageVersionHistoryMode(pageUrl, toggle));

  const handleSelectPageVersion = (pageUrl: string, versionIndex: number) =>
    dispatch(selectSitePageVersion(pageUrl, versionIndex));

  return {
    selectedDetailInfo,
    isSitePageInVersionHistoryMode,
    isSitePageInEditMode,
    selectSitePageDetail: handleSelectSitePageDetail,
    setSitePageInValidProperty: handleSetSitePageInValidProperty,
    clearSitePageInValid: handleClearSitePageInValid,
    setSitePageEditMode: handleSetSitePageEditMode,
    isSitePageInValid: sitePageInValidProperties.size > 0,
    getSitePageVersions: handleGetSitePageVersions,
    setSitePageVersion: handleSetSitePageVersion,
    setSitePageVersionHistoryMode: handleSetSitePageVersionHistoryMode,
    selectSitePageVersion: handleSelectPageVersion,
  };
};

export const useSitePagesMethods = () => {
  const dispatch = useDispatch();

  const handleCheckOutSitePage = (params: ICheckoutParams) =>
    dispatch(checkOutSitePage(params));

  const handleDiscardCheckOutSitePage = (params: IDiscardCheckoutParams) =>
    dispatch(discardCheckOutSitePage(params));

  const handleSaveAsDraftSitePage = (
    sitePageId: number,
    sitePageUrl: string
  ) => {
    return dispatch(saveAsDraftSitePage(sitePageId, sitePageUrl));
  };

  const handleRepublishSitePage = (sitePageId: number, sitePageUrl: string) => {
    return dispatch(republishSitePage(sitePageId, sitePageUrl));
  };

  const handleGetSitePageByUrl = (url: string) =>
    dispatch(getSitePageByUrl(url));

  const handleUpdateSitePage = (pathKeys: IPathKeys, sitePageValue) => {
    return dispatch(updateSitePage(pathKeys, sitePageValue));
  };

  return {
    getSitePageByUrl: handleGetSitePageByUrl,
    onCheckOutSitePage: handleCheckOutSitePage,
    onDiscardCheckOutSitePage: handleDiscardCheckOutSitePage,
    onSaveAsDraftSitePage: handleSaveAsDraftSitePage,
    onRepublishSitePage: handleRepublishSitePage,
    updateSitePage: handleUpdateSitePage,
  };
};

export const useSitePageVersions = (pageUrl: string) => {
  const versions = useSelector<IRootReducerState, ISitePageVersions>(
    (state) => state.sitePages.versions
  );
  const currentVersionIndex = versions[pageUrl]
    ? versions[pageUrl]?.currentIndex
    : -1;
  const pageVersions = versions[pageUrl]?.items || [];
  const selectedPageVersion = pageVersions.length
    ? pageVersions[currentVersionIndex]
    : null;

  return {
    currentVersionIndex,
    pageVersions,
    selectedPageVersion,
  };
};
