import React, { FC, useCallback, useLayoutEffect, useState } from "react";

import { LabeledInput, PickerInput, Text, TextInput } from "@epam/loveship";

import { IPageDetailsProps } from "Components/page-details/page-details.interface";
import { PageDetailsHeader } from "Components/page-details/page-details-header";
import { withNaming } from "Helpers/bemClassName";
import { linkHesColors, validLinkRegex } from "Helpers/constants";
import { CardKeys, usePageDetailsActions } from "@App/hooks/usePageDetails";
import {
  IParentRichTextEditorWebPart,
  IWebPart,
  WebPartTypes,
} from "SP/sitePages/sitePages.types";
import { useArrayDataSource, DataRowProps } from "@epam/uui-core";

const getCardData = (card: IWebPart) => {
  if (card.type === WebPartTypes.ParentRichTextEditorWebPart) {
    return card.data as IParentRichTextEditorWebPart;
  }
};

export const PageDetailsParent: FC<IPageDetailsProps> = ({ sitePageUrl }) => {
  const cn = withNaming("page-details");
  const [link, setLink] = useState("");
  const [linkColor, setLinkColor] = useState("");
  const { card, title, onTitleChange, handleDeleteCard, handleUpdateSitePage } =
    usePageDetailsActions(sitePageUrl);

  const colorDataSource = useArrayDataSource(
    {
      items: linkHesColors.map((color) => ({
        id: color,
        color,
      })),
    },
    []
  );

  const handleTitleChange = (value: string) => {
    onTitleChange(value);
    handleUpdateSitePage(value, CardKeys.webPartTitle);
  };

  const handleLinkChange = (value: string) => {
    setLink(value);
    handleUpdateSitePage(value, CardKeys.webPartTitleLink);
  };

  const handleColorChange = (value) => {
    setLinkColor(value);
    handleUpdateSitePage(value, CardKeys.webPartLinkColor);
  };

  const renderLinkColorRow = useCallback((rowProps: DataRowProps<any, any>) => {
    return (
      <div
        key={rowProps.rowKey}
        style={{ backgroundColor: rowProps.value.color }}
        className={cn("link-color-row", ["flex items-center"])}
        onClick={() => rowProps.onSelect(rowProps)}
      >
        <Text size="18" color="night900" fontSize="14" lineHeight="18">
          {rowProps.value.color}
        </Text>
      </div>
    );
  }, []);

  useLayoutEffect(() => {
    if (card) {
      const cardData = getCardData(card);
      onTitleChange(cardData.webPartTitle);
      setLink(cardData.webPartTitleLink);
      setLinkColor(cardData.webPartLinkColor);
    }
  }, []);

  return (
    <div className={cn("wrapper")}>
      <PageDetailsHeader
        title="Card"
        sitePageUrl={sitePageUrl}
        onDelete={() => handleDeleteCard(CardKeys.webPartTitle)}
      />
      <div className={cn("row", { card: true })}>
        <div className={cn("col")}>
          <LabeledInput
            isRequired
            size="none"
            label="Title"
            isInvalid={!title}
            validationMessage="This field is required!"
          >
            <TextInput
              placeholder="Title"
              value={title}
              onValueChange={handleTitleChange}
              isInvalid={!title}
            />
          </LabeledInput>
          <LabeledInput
            size="none"
            label="Link"
            isInvalid={!validLinkRegex.test(link)}
            validationMessage="Link is invalid!"
          >
            <TextInput
              placeholder="Link"
              value={link}
              onValueChange={handleLinkChange}
              isInvalid={!validLinkRegex.test(link)}
            />
          </LabeledInput>
          <LabeledInput
            isRequired
            size="none"
            label="Color"
            isInvalid={!linkColor}
            validationMessage="This field is required!"
          >
            <PickerInput
              selectionMode="single"
              valueType="id"
              getName={(item) => item.color}
              value={linkColor}
              onValueChange={(color) => handleColorChange(color)}
              dataSource={colorDataSource}
              renderRow={renderLinkColorRow}
            />
          </LabeledInput>
        </div>
      </div>
    </div>
  );
};
