import React, { FC } from "react";

import { Text } from "@epam/loveship";

import { withNaming } from "Helpers/bemClassName";

import { IPageDetailsMainProps } from "./page-details.interface";

export const SELECT_COMPONENT_TEXT = "Select section or component to edit";

export const PageDetailsMain: FC<IPageDetailsMainProps> = () => {
  const cn = withNaming("page-details");

  return (
    <div className={cn("wrapper")}>
      <div className={cn("row")}>
        <Text fontSize="14" color="night600">
          {SELECT_COMPONENT_TEXT}
        </Text>
      </div>
    </div>
  );
};
