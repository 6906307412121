import React, { FC } from "react";

import { Panel, Text, LinkButton } from "@epam/loveship";

import { RichTextEditor } from "Components/rich-text-editor";
import { withNaming } from "Helpers/bemClassName";
import { isDetailCard } from "Helpers/utils";
import { useSitePagesActions } from "@App/hooks/useSitePages";
import { IRichTextEditorWebPart } from "SP/sitePages/sitePages.types";
import { ReactComponent as LinkIcon } from "@App/assets/link-icon.svg";

import { IWebPartItemProps } from "../web-parts";
import { ISelectDetailProps } from "../web-parts-web-part";

import "./web-parts-card.scss";
import {
  DetailTypes,
  ISelectedDetailInfo,
} from "Store/reducers/sitePages.reducer.types";
import ImageComponent from "Components/image/image";

interface IWebPartsCardProps extends IWebPartItemProps {
  data: IRichTextEditorWebPart;
  surroundedBy: "accordion" | "card";
}

export function isCardSelected(
  selectedDetail: ISelectedDetailInfo,
  { sectionIndex, webPartIndex, column }: ISelectDetailProps
): boolean {
  return (
    isDetailCard(selectedDetail.type) &&
    selectedDetail.sectionIndex === sectionIndex &&
    selectedDetail.column === column &&
    selectedDetail.webPartIndex === webPartIndex
  );
}

export const WebPartsCard: FC<IWebPartsCardProps> = ({
  data,
  webPart,
  pathKeys,
  selectDetailProps,
  surroundedBy,
}) => {
  const cn = withNaming("web-parts-card");
  const { isSitePageInEditMode, selectedDetailInfo, selectSitePageDetail } =
    useSitePagesActions();

  const handleSelectDetail = () => {
    if (isSitePageInEditMode) {
      selectSitePageDetail({ type: DetailTypes.card, ...selectDetailProps });
    }
  };

  const slateEditor = (
    <RichTextEditor
      cx={cn("slate-editor")}
      isEditMode={isSitePageInEditMode}
      data={data}
      pathKeys={pathKeys}
      webPart={webPart}
    />
  );

  const content = (
    <>
      <div className={cn("row", { header: true }, ["flex justify-between"])}>
        <Text
          color="night800"
          size="24"
          font="sans-semibold"
          fontSize="18"
          fontWeight="400"
          lineHeight="24"
        >
          {data.accordionTitle}
        </Text>
        {data.iconUrl && (
          <ImageComponent
            src={data.iconUrl}
            style={{ width: "36px", height: "36px" }}
          />
        )}
      </div>
      <div className={cn("row")}>
        <div className="divider" />
        {slateEditor}
      </div>
    </>
  );

  return (
    <Panel
      cx={cn(
        "",
        {
          selected: isCardSelected(selectedDetailInfo, selectDetailProps),
          "clear-card": !surroundedBy,
          "edit-mode": isSitePageInEditMode,
        },
        ["shadow"]
      )}
      background="white"
      onClick={handleSelectDetail}
      data-testid="web-parts-card"
    >
      <a
        href={!isSitePageInEditMode && (data.btnUrl || null)}
        target="_blank"
        rel="noreferrer"
        className={cn("card-link", {
          active: data.btnUrl && !isSitePageInEditMode,
        })}
        data-testid="web-parts-card"
      >
        {surroundedBy === "card" || !surroundedBy ? slateEditor : content}

        <LinkButton
          cx={cn("additional-link", { "not-active": !data.btnUrl })}
          icon={LinkIcon}
          iconPosition="right"
          caption="OPEN"
          target="_blank"
          href={null}
          rawProps={{ "data-testid": "link-button" }}
        />
      </a>
    </Panel>
  );
};
