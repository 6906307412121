import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import { FlexRow, LinkButton, Panel, Text } from "@epam/loveship";

import { RichTextEditor } from "Components/rich-text-editor";
import { withNaming } from "Helpers/bemClassName";
import { handleClick, isInternalUrl } from "Helpers/utils";
import { useSitePagesActions } from "@App/hooks/useSitePages";
import { IParentRichTextEditorWebPart } from "SP/sitePages/sitePages.types";
import { IWebPartItemProps } from "../web-parts";
import { isCardSelected } from "../web-parts-card/web-parts-card";

import "./web-parts-parent.scss";
import { DetailTypes } from "Store/reducers/sitePages.reducer.types";

interface IWebPartsParentProps extends IWebPartItemProps {
  data: IParentRichTextEditorWebPart;
}

export const WebPartsParent: FC<IWebPartsParentProps> = ({
  data,
  webPart,
  pathKeys,
  selectDetailProps,
}) => {
  const cn = withNaming("web-parts-card");
  const { push } = useHistory();

  const { isSitePageInEditMode, selectedDetailInfo, selectSitePageDetail } =
    useSitePagesActions();
  const isTitleHasLink = !!data.webPartTitleLink;

  const handleSelectDetail = () => {
    if (isSitePageInEditMode) {
      selectSitePageDetail({
        type: DetailTypes.parentCard,
        ...selectDetailProps,
      });
    }
  };

  const handleTitleLinkClick =
    (link?: string) =>
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (!link) return;

      if (isInternalUrl(link)) {
        event.preventDefault();
        handleClick(link, event, push);
      } else {
        window.open(link);
      }
    };

  return (
    <Panel
      cx={cn(
        "",
        {
          selected: isCardSelected(selectedDetailInfo, selectDetailProps),
          "edit-mode": isSitePageInEditMode,
        },
        ["shadow"]
      )}
      background="white"
      onClick={handleSelectDetail}
      rawProps={{ "data-testid": "parent-card" }}
    >
      <FlexRow cx={cn("title-wrapper")}>
        <div
          className={cn("title-text-wrapper", [
            "flex justify-start items-start",
          ])}
          style={{ background: data.webPartLinkColor }}
        >
          {isTitleHasLink ? (
            <LinkButton
              cx={cn("link-text")}
              caption={data.webPartTitle}
              size="none"
              color="sky"
              onClick={handleTitleLinkClick(data.webPartTitleLink)}
            />
          ) : (
            <Text
              cx={cn("title-text")}
              color="night900"
              font="sans-semibold"
              fontSize="18"
              lineHeight="24"
            >
              {data.webPartTitle}
            </Text>
          )}
        </div>
      </FlexRow>
      <RichTextEditor
        cx={cn("slate-editor")}
        isEditMode={isSitePageInEditMode}
        data={data}
        pathKeys={pathKeys}
        webPart={webPart}
      />
    </Panel>
  );
};
