import {
  IPageInfo,
  LibraryName,
  SectionTypes,
  WebPartTypes,
} from "./sitePages.types";

const mainExpandArray = ["Author", "Editor", "File", "ContentType"];

export const sitePageVersionsListRequestInfo = {
  selectArray: [
    "Title",
    "pageInfo",
    "Editor",
    "Modified",
    "VersionLabel",
    "OData__x005f_CheckinComment",
  ],
};

export const sitePagesListRequestInfo = {
  selectArray: [
    "Id",
    "Title",
    "Created",
    "pageInfo",
    "Author/Id",
    "Author/Title",
    "Author/EMail",
    "Editor/Id",
    "Editor/Title",
    "Editor/EMail",
    "Modified",
    "CheckoutUser/Id",
    "CheckoutUser/Title",
    "CheckoutUser/EMail",
    "File_x0020_Type",
  ],
  expandArray: mainExpandArray.concat("CheckoutUser"),
};

export const emptyTable = `<table style="border-collapse: collapse; width: 100%;">
<tbody>
<tr>
<td style="width: 50%; vertical-align: top;">&nbsp;</td>
<td style="width: 50%; vertical-align: top;">&nbsp;</td>
</tr>
</tbody>
</table>`;

export const childPageInfo: IPageInfo = {
  showSubscribeButton: false,
  attachedFiles: { library: LibraryName.regulations, items: [] },
  sections: [
    {
      type: SectionTypes.OneColumnLayout,
      data: {
        webParts: [
          {
            type: WebPartTypes.RichTextEditorWebPart,
            data: {
              accordionTitle: "Overview",
              surroundedBy: "accordion",
              markup: "",
            },
          },
          {
            type: WebPartTypes.RichTextEditorWebPart,
            data: {
              accordionTitle: "Related Artifacts",
              surroundedBy: "accordion",
              markup: emptyTable,
            },
          },
          {
            type: WebPartTypes.RichTextEditorWebPart,
            data: {
              accordionTitle: "Additional Information",
              surroundedBy: "accordion",
              markup: emptyTable,
            },
          },
        ],
      },
    },
  ],
};

export const parentPageInfo: IPageInfo = {
  showSubscribeButton: false,
  attachedFiles: { library: LibraryName.regulations, items: [] },
  sections: [
    {
      type: SectionTypes.TwoColumnsLayout,
      data: {
        left: [],
        right: [],
      },
    },
  ],
};
