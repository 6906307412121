import React, { FC, Fragment } from "react";

import { LinkButton } from "@epam/loveship";
import { IconContainer } from "@epam/uui-components";

import { ReactComponent as chevronRight } from "@epam/assets/icons/common/navigation-chevron-right-18.svg";
import { withNaming } from "Helpers/bemClassName";
import { IBreadcrumbsProps } from "./breadcrumbs.types";
import { isLastItem } from "Helpers/utils";

import "./breadcrumbs.scss";

export const Breadcrumbs: FC<IBreadcrumbsProps> = ({ items = [], onClick }) => {
  const cn = withNaming("breadcrumbs");

  if (!items.length) return null;
  return (
    <div className={cn("", ["flex items-center"])} data-testid="breadcrumbs">
      {items.map((item, index) => (
        <Fragment key={item.title}>
          <LinkButton
            cx={cn("", { link: !!item.value })}
            color="night800"
            caption={item.title}
            {...(onClick && { onClick: onClick(item) })}
          />
          {!isLastItem(items, index) && (
            <IconContainer cx={cn("chevron-right")} icon={chevronRight} />
          )}
        </Fragment>
      ))}
    </div>
  );
};
