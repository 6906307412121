export const validLinkRegex =
  /^(?:https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))?$/;

export const linkHesColors = [
  "#EFF4FF",
  "#FCEDEB",
  "#F2FACB",
  "#FFF7E9",
  "#EFE6FF",
  "#EBFCF6",
  "#FCEBFA",
  "#EFF0F5",
];

export const isSharepointHost =
  window.location.host.includes(".sharepoint.com");

export const sitePageUrl = process.env.REACT_APP_HOME_PAGE;

export const errorMessages = {
  pageNotFound: "Page not found",
  noCheckedInVersion:
    "You cannot discard check out because there is no checked in version of the document.",
  notAuthorized: "You have no permissions!",
};
