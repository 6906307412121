import { isSharepointHost } from "Helpers/constants";
import { UserPhoto } from "Helpers/storage";
import { spWeb, graphMe, spProfiles } from "SP/configure";
import { IUser } from "Store/actions/sitePage.actions.types";

export class UsersService {
  async getCurrentUser(): Promise<IUser> {
    const profileDataPromise = spProfiles.myProperties.select("DisplayName")();
    const photoPromise = !isSharepointHost && this.getCurrentUserPhoto();
    const userDataPromise = spWeb.currentUser.select("Title", "Email", "Id")();

    const [profileData, userData, photo] = await Promise.all([
      profileDataPromise,
      userDataPromise,
      photoPromise,
    ]);

    return {
      id: userData.Id,
      name: profileData?.DisplayName ?? "",
      jobTitle: userData.Title,
      email: userData.Email,
      photo,
    };
  }

  async getCurrentUserPhoto(): Promise<string> {
    const userPhotoUrl = UserPhoto.get();
    if (userPhotoUrl) {
      return userPhotoUrl;
    }

    try {
      const photoBuffer = await graphMe.photo.getBuffer();

      if (!photoBuffer) {
        return "";
      }

      const photoBlob = new Blob([photoBuffer], {
        type: "image/jpeg",
      });
      const photoUrl = await this.getBase64ImageFromBlob(photoBlob);
      UserPhoto.set(photoUrl, 60 * 60);

      return photoUrl;
    } catch (e) {
      console.log("Error when getting user avatar", e);
    }
  }

  getBase64ImageFromBlob(photoBlob: Blob): Promise<string> {
    return new Promise((res) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        res(reader.result as string);
      });
      reader.readAsDataURL(photoBlob);
    });
  }
}
