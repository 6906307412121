import React, { FC } from "react";

import { FlexRow } from "@epam/loveship";

import { withNaming } from "Helpers/bemClassName";
import { useSitePagesActions } from "@App/hooks/useSitePages";
import {
  IOneColumnLayout,
  IOneThirdLeftLayout,
  ISection,
  IThreeColumnsLayout,
  ITwoColumnsLayout,
  SectionTypes,
} from "SP/sitePages/sitePages.types";

import "./sections-section.scss";
import {
  DetailTypes,
  ISelectedDetailInfo,
} from "Store/reducers/sitePages.reducer.types";
import { AddSectionButton } from "../sections-add-section";
import {
  OneColumnLayout,
  OneThirdLayout,
  ThreeColumnsLayout,
  TwoColumnsLayout,
} from "../sections-layouts";
import { RowsLayout } from "../sections-layouts/sections-layouts";
import { SectionDescription } from "Components/Sections/section-description";

interface ISectionProps {
  section: ISection;
  sectionIndex: number;
  isLastSection: boolean;
}

function getOneThirdLayoutProps(section: ISection) {
  return {
    isLeft: section.type === "OneThirdLeftLayout",
    isRight: section.type === "OneThirdRightLayout",
  };
}

function isSectionSelected(
  selectedDetail: ISelectedDetailInfo,
  sectionIndex: number
) {
  return (
    selectedDetail.type === DetailTypes.section &&
    selectedDetail.sectionIndex === sectionIndex
  );
}

export const Section: FC<ISectionProps> = ({
  section,
  sectionIndex,
  isLastSection,
}) => {
  const cn = withNaming("section");

  const { isSitePageInEditMode, selectedDetailInfo, selectSitePageDetail } =
    useSitePagesActions();

  const handleSelectSection = () => {
    selectSitePageDetail({ type: DetailTypes.section, sectionIndex });
  };
  const renderLayout = () => {
    switch (section.type) {
      case SectionTypes.OneColumnLayout:
        return (
          <OneColumnLayout
            sectionIndex={sectionIndex}
            data={section.data as IOneColumnLayout}
          />
        );
      case SectionTypes.TwoColumnsLayout:
        return (
          <TwoColumnsLayout
            sectionIndex={sectionIndex}
            data={section.data as ITwoColumnsLayout}
          />
        );
      case SectionTypes.ThreeColumnsLayout:
        return (
          <ThreeColumnsLayout
            sectionIndex={sectionIndex}
            data={section.data as IThreeColumnsLayout}
          />
        );
      case SectionTypes.RowsLayout:
        return (
          <RowsLayout
            sectionIndex={sectionIndex}
            data={section.data as IOneColumnLayout}
            description={section.description}
          />
        );
      default:
        return (
          <OneThirdLayout
            sectionIndex={sectionIndex}
            data={section.data as IOneThirdLeftLayout}
            {...getOneThirdLayoutProps(section)}
          />
        );
    }
  };

  const showDescription =
    section.description && section.type !== SectionTypes.RowsLayout;

  return (
    <div
      key={sectionIndex}
      className={cn(
        "",
        {
          "edit-mode": isSitePageInEditMode,
          selected: isSectionSelected(selectedDetailInfo, sectionIndex),
        },
        ["flex flex-col"]
      )}
    >
      {isSitePageInEditMode && (
        <>
          <AddSectionButton position="prev" sectionIndex={sectionIndex} />
          <div
            className={cn("badge", ["cursor-pointer"])}
            onClick={handleSelectSection}
          >
            Section {sectionIndex + 1}
          </div>
        </>
      )}
      {showDescription && <SectionDescription text={section.description} />}
      <FlexRow cx={cn("layouts")}>{renderLayout()}</FlexRow>
      {isSitePageInEditMode && isLastSection && (
        <AddSectionButton position="next" sectionIndex={sectionIndex} />
      )}
    </div>
  );
};
