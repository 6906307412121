import htmlParser from "html-react-parser";
import React, { FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import { Spinner } from "@epam/loveship";
import { Editor } from "@tinymce/tinymce-react";

import { ShowCurtain } from "Components/show-curtain";
import { withNaming } from "Helpers/bemClassName";
import { IPathKeys } from "Helpers/sitePageUpdater";
import {
  handleClick,
  isInternalUrl,
  isPageUrl,
  replacePageUrlPrefix,
} from "Helpers/utils";
import {
  IParentRichTextEditorWebPart,
  IRichTextEditorWebPart,
  IWebPart,
} from "SP/sitePages/sitePages.types";

import { useEditor } from "./rich-text-editor.hooks";
import {
  tinyFontFormats,
  tinyFontSizeFormats,
  tinyPlugins,
  tinyToolbar,
} from "./tinymce/tinymce.configs";
import { tinymceStyle } from "./tinymce/tinymce.style";

import "./rich-text-editor.scss";

interface IRichTextEditorProps {
  cx: string;
  isEditMode: boolean;
  data: IRichTextEditorWebPart | IParentRichTextEditorWebPart;
  webPart: IWebPart;
  pathKeys: IPathKeys;
}

enum LinkClasses {
  external = "ext_link",
  restricted = "res_link",
  list = "list_link",
  parent = "parent_link",
}

const dataKey = "markup";

const encodedParentPagesURI = encodeURI("/Policy/Parent Pages/");

const isAddParentClass = (link: any) =>
  link.href.includes(encodedParentPagesURI) &&
  !(
    link.classList.contains(LinkClasses.list) ||
    link.classList.contains(LinkClasses.restricted)
  );

export const RichTextEditor: FC<IRichTextEditorProps> = ({
  cx,
  isEditMode,
  data,
  pathKeys,
  webPart,
}) => {
  const cn = withNaming("rich-text-editor");
  const { push } = useHistory();
  const editorRef = useRef<any>(null);
  const parsedViewRef = useRef<any>(null);
  const [loader, setLoader] = useState(true);
  const [value, onValueChange] = useEditor({
    data,
    dataKey,
    pathKeys,
    webPart,
  });

  useEffect(() => {
    if (parsedViewRef.current) {
      const handleInternalClick = (href: string) => {
        return (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          event.preventDefault();
          handleClick(href, event, push);
        };
      };

      const handleLinkElement = (link: any) => {
        if (isInternalUrl(link.href)) {
          if (isAddParentClass(link)) link.classList.add(LinkClasses.parent);
          if (link.target !== "_blank") {
            if (isPageUrl(link.href)) {
              link.onclick = handleInternalClick(link.href);
            } else {
              link.setAttribute("target", "_blank");
            }
          }
        } else {
          link.classList.add(LinkClasses.external);
        }
        link.href = replacePageUrlPrefix(link.href);
      };

      const links = parsedViewRef.current.querySelectorAll(
        "a:not([href^='mailto:'])"
      );
      links.forEach(handleLinkElement);
    }
  }, [pathKeys[0]]);

  const htmlContent = data[dataKey] && htmlParser(data[dataKey]);

  return (
    <div className={cn("", [cx])}>
      {loader && isEditMode && <Spinner />}
      {!isEditMode && (
        <div
          className={cn("parsed-view", {
            displayDividers: "displayDividers" in data && data.displayDividers,
          })}
          ref={parsedViewRef}
        >
          {"showCurtain" in data && data.showCurtain ? (
            <ShowCurtain maxHeight={180}>{htmlContent}</ShowCurtain>
          ) : (
            htmlContent
          )}
        </div>
      )}
      {isEditMode && (
        <Editor
          onInit={(evt, editor) => (editorRef.current = editor)}
          apiKey={process.env.REACT_APP_TINY_MCE_KEY}
          value={value}
          onEditorChange={onValueChange}
          init={{
            link_class_list: [
              { title: "None", value: "" },
              { title: "Restricted Link", value: LinkClasses.restricted },
              { title: "List Link", value: LinkClasses.list },
            ],
            content_style: tinymceStyle,
            setup(editor) {
              editor.on("init", () => {
                setLoader(false);
              });
            },
            height: 500,
            menubar: true,
            font_formats: tinyFontFormats,
            plugins: tinyPlugins,
            fontsize_formats: tinyFontSizeFormats,
            toolbar: tinyToolbar,
            browser_spellcheck: true,
            relative_urls: false,
            remove_script_host: false,
          }}
        />
      )}
    </div>
  );
};
