import produce from "immer";
import { Reducer } from "redux";
import {
  ISitePagesActionsTypes,
  SitePagesActionsTypes,
} from "Store/actions/sitePages.actions";
import { ISitePage, ISitePageVersion } from "SP/sitePages/sitePages.types";
import { ISelectedDetailInfo, DetailTypes } from "./sitePages.reducer.types";
import { setSitePageValue } from "Helpers/sitePageUpdater";
import { IUserWithPerms } from "Store/actions/sitePage.actions.types";

export type ISitePages = { [key: string]: ISitePage };

export type ISitePageVersions = {
  [key: string]: { currentIndex: number; items: ISitePageVersion[] };
};

export interface ISitePagesState {
  sitePages: ISitePages;
  pageLoading: boolean;
  isSitePageInVersionHistoryMode: boolean;
  isSitePageInEditMode: boolean;
  selectedDetailInfo: ISelectedDetailInfo;
  currentUser: IUserWithPerms | null;
  presetSaveErrorText: string;
  sitePageInValidProperties: Set<string>;
  editPageBackup: ISitePage;
  versions: ISitePageVersions;
  errors: string[];
  criticalError?: string;
}

const initialState: ISitePagesState = {
  sitePages: {},
  pageLoading: false,
  isSitePageInVersionHistoryMode: false,
  isSitePageInEditMode: false,
  selectedDetailInfo: {
    type: DetailTypes.main,
  },
  currentUser: null,
  presetSaveErrorText: "",
  sitePageInValidProperties: new Set(),
  editPageBackup: {} as ISitePage,
  versions: {},
  errors: [],
};

const resetSelectedDetailInfo = (draft) => {
  draft.selectedDetailInfo = {
    type: DetailTypes.main,
  };
};

const resetSitePage = (draft, sitePageUrl) => {
  if (draft.editPageBackup) {
    draft.sitePages[sitePageUrl] = draft.editPageBackup;
    draft.editPageBackup = null;
  }
};

const finishSitePageEdit = (draft) => {
  draft.isSitePageInEditMode = false;
  draft.sitePageInValidProperties.clear();
  draft.editPageBackup = null;
  draft.pageLoading = false;
};

export const sitePagesReducer: Reducer<
  ISitePagesState,
  ISitePagesActionsTypes
> = produce((draft, action) => {
  if (!draft) return;
  switch (action.type) {
    case SitePagesActionsTypes.GET_SITE_PAGE_BY_URL:
    case SitePagesActionsTypes.CHECK_OUT_SITE_PAGE:
    case SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE:
    case SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS:
    case SitePagesActionsTypes.SET_SITE_PAGE_VERSION:
    case SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE:
    case SitePagesActionsTypes.REPUBLISH_SITE_PAGE:
      draft.pageLoading = true;
      break;
    case SitePagesActionsTypes.GET_SITE_PAGE_BY_URL_FAILURE:
    case SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE_FAILURE:
    case SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS_FAILURE:
    case SitePagesActionsTypes.SET_SITE_PAGE_VERSION_FAILURE:
    case SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE_FAILURE:
    case SitePagesActionsTypes.REPUBLISH_SITE_PAGE_FAILURE:
      draft.pageLoading = false;
      break;
    case SitePagesActionsTypes.CHECK_OUT_SITE_PAGE_FAILURE:
      draft.errors = [...draft.errors, action.error];
      draft.pageLoading = false;
      break;
    case SitePagesActionsTypes.GET_SITE_PAGE_BY_URL_SUCCESS:
      draft.pageLoading = false;
      draft.sitePages[action.payload.url] = action.payload.sitePage;
      break;
    case SitePagesActionsTypes.UPDATE_SITE_PAGE:
      setSitePageValue(draft.sitePages, action.pathKeys, action.sitePageValue);
      break;
    case SitePagesActionsTypes.SELECT_SITE_PAGE_DETAIL:
      draft.selectedDetailInfo = action.detailInfo;
      break;
    case SitePagesActionsTypes.GET_CURRENT_USER_SUCCESS:
      draft.currentUser = action.payload;
      break;
    case SitePagesActionsTypes.SET_SITE_PAGE_IN_VALID_PROPERTY:
      if (action.toggle) {
        draft.sitePageInValidProperties.add(action.property);
      } else {
        draft.sitePageInValidProperties.delete(action.property);
      }
      break;
    case SitePagesActionsTypes.CLEAR_SITE_PAGE_IN_VALID:
      draft.sitePageInValidProperties.clear();
      break;
    case SitePagesActionsTypes.SET_SITE_PAGE_EDIT_MODE:
      if (action.toggle) {
        draft.editPageBackup = draft.sitePages[action.sitePageUrl];
      } else {
        resetSitePage(draft, action.sitePageUrl);
        resetSelectedDetailInfo(draft);
      }
      draft.isSitePageInEditMode = action.toggle;
      break;
    case SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE_SUCCESS:
    case SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE_SUCCESS:
    case SitePagesActionsTypes.REPUBLISH_SITE_PAGE_SUCCESS:
      draft.sitePages[action.sitePageUrl] = action.sitePage;
      finishSitePageEdit(draft);
      resetSelectedDetailInfo(draft);
      break;
    case SitePagesActionsTypes.CHECK_OUT_SITE_PAGE_SUCCESS:
      draft.sitePages[action.sitePageUrl].CheckoutUser = action.currentUser;
      draft.editPageBackup.CheckoutUser = action.currentUser;
      draft.pageLoading = false;
      break;
    case SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS_SUCCESS:
      draft.pageLoading = false;
      draft.versions[action.pageUrl] = {
        currentIndex: 0,
        items: action.versions,
      };
      draft.isSitePageInVersionHistoryMode = true;
      break;
    case SitePagesActionsTypes.SET_SITE_PAGE_VERSION_HISTORY_MODE:
      draft.isSitePageInVersionHistoryMode = action.toggle;
      break;
    case SitePagesActionsTypes.SET_SITE_PAGE_VERSION_SUCCESS:
      draft.pageLoading = false;
      draft.isSitePageInVersionHistoryMode = false;
      draft.sitePages[action.pageUrl].pageInfo = action.page.pageInfo;
      draft.versions[action.pageUrl] = null;
      break;
    case SitePagesActionsTypes.SELECT_SITE_PAGE_VERSION:
      draft.versions[action.pageUrl].currentIndex = action.versionIndex;
      break;
    case SitePagesActionsTypes.CLEAR_SITE_PAGE_ERRORS:
      draft.errors = [];
      break;
    case SitePagesActionsTypes.SET_SITE_PAGE_ERROR:
      draft.errors = [...draft.errors, action.error];
      break;
    case SitePagesActionsTypes.SET_CRITICAL_ERROR:
      draft.criticalError = action.error;
      break;
  }
}, initialState);
