import { Text, ErrorNotification } from "@epam/uui";
import { INotification, useUuiContext } from "@epam/uui-core";
import { clearSitePageErrors } from "Store/actions/sitePages.actions";
import { IRootReducerState } from "Store/rootReducer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useNotification = () => {
  const dispatch = useDispatch();
  const { uuiNotifications } = useUuiContext();
  const sitePageErrors = useSelector<IRootReducerState, any[]>(
    (state) => state.sitePages.errors
  );

  const showErrorNotification = (message: string) => {
    uuiNotifications
      .show(
        (props: INotification) => (
          <ErrorNotification {...props}>
            <Text size="36" fontSize="14">
              {message}
            </Text>
          </ErrorNotification>
        ),
        {
          position: "bot-left",
          duration: 7,
        }
      )
      .catch(() => null);
  };

  useEffect(() => {
    if (sitePageErrors.length) {
      sitePageErrors.forEach((e) => {
        showErrorNotification(e);
      });
      dispatch(clearSitePageErrors());
    }
  }, [sitePageErrors]);

  return {
    showErrorNotification,
  };
};
