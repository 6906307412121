import { useDispatch, useSelector } from "react-redux";
import { IUserWithPerms } from "Store/actions/sitePage.actions.types";
import { getCurrentUser } from "Store/actions/sitePages.actions";

import { IRootReducerState } from "Store/rootReducer";

export const useUsersData = () => {
  const currentUser = useSelector<IRootReducerState, IUserWithPerms | null>(
    (state) => state.sitePages.currentUser
  );

  const presetSaveErrorText = useSelector<IRootReducerState, string>(
    (state) => state.sitePages.presetSaveErrorText
  );

  return {
    currentUser,
    presetSaveErrorText,
  };
};

export const useUsersMethods = () => {
  const dispatch = useDispatch();

  const handleGetCurrentUser = (cb?: (IUser) => void) =>
    dispatch(getCurrentUser(cb));

  return {
    getCurrentUser: handleGetCurrentUser,
  };
};
