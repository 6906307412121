import {
  AccountInfo,
  Configuration,
  EventMessage,
  EventMessageUtils,
  InteractionStatus,
  PublicClientApplication,
} from "@azure/msal-browser";

const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_GRAPH_CLIENT_ID,
    authority: process.env.REACT_APP_GRAPH_AUTHORITY,
    navigateToLoginRequestUrl: false,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
  },
};

export const msalInstance = (function () {
  return new PublicClientApplication(msalConfig);
})();

const sharepointOrigin = new URL(process.env.REACT_APP_BASE_URL).origin;

export const getOnAuthQueryableHandler =
  (scopes?: string[]) =>
  async (url: URL, init: RequestInit): Promise<[URL, RequestInit]> => {
    try {
      const token = await msalInstance.acquireTokenSilent({
        account: interactions.getInteractionAccount(),
        scopes: scopes ?? [`${sharepointOrigin}/AllSites.FullControl`],
      });

      const initHeaders = init.headers ?? ({} as any);
      initHeaders["Authorization"] = `Bearer ${token.accessToken}`;

      return [url, init];
    } catch (e) {
      console.log("acquireTokenSilent error:", e);
      msalInstance.clearCache();
      msalInstance.loginRedirect();
    }
  };

export const interactions = (function () {
  enum MsalProviderActionType {
    UNBLOCK_INPROGRESS = "UNBLOCK_INPROGRESS",
    EVENT = "EVENT",
  }

  type State = {
    inProgress: InteractionStatus;
    account: AccountInfo;
  };

  const state: State = {
    inProgress: InteractionStatus.Startup,
    account: null,
  };

  function getInteractionStatus() {
    return state.inProgress;
  }

  function getInteractionAccount() {
    return state.account;
  }

  function setInteractionsAccount(account: AccountInfo) {
    state.account = account;
  }

  function updateState(message: EventMessage, type: MsalProviderActionType) {
    let newInProgress: InteractionStatus = state.inProgress;

    switch (type) {
      case MsalProviderActionType.UNBLOCK_INPROGRESS:
        if (state.inProgress === InteractionStatus.Startup) {
          newInProgress = InteractionStatus.None;
        }
        break;
      case MsalProviderActionType.EVENT:
        const status = EventMessageUtils.getInteractionStatusFromEvent(
          message,
          state.inProgress
        );
        if (status) {
          newInProgress = status;
        }
        break;
      default:
        throw new Error(`Unknown action type: ${type}`);
    }

    if (newInProgress !== state.inProgress) {
      state.inProgress = newInProgress;
    }
  }

  return {
    updateState,
    getInteractionStatus,
    MsalProviderActionType,
    getInteractionAccount,
    setInteractionsAccount,
  };
})();
