import { DetailTypes } from "Store/reducers/sitePages.reducer.types";

export function isLastItem<T>(items: T[], index: number): boolean {
  return items.length - 1 === index;
}

export function handleClick(
  href: string,
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  push: {
    (path: string, state?: unknown): void;
  }
) {
  if (checkOpenLinkInNewTab(event)) window.open(href);
  else {
    if (href.toLowerCase().includes("siteassets")) {
      window.location.href = href;
    } else {
      push(getInternalUrl(href));
    }
  }
}

export function checkOpenLinkInNewTab(
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) {
  return event.ctrlKey || event.metaKey;
}

export function getInternalUrl(url: string): string {
  if (!url) return "";

  const Url = process.env.REACT_APP_BASE_URL;
  const appUrl = `${Url}/index.aspx`;

  if (url.includes(appUrl)) {
    return url.replace(appUrl, "");
  }

  return url.replace(`${Url}/SitePages`, "");
}

export function isInternalUrl(url: string): boolean {
  if (!url) return false;

  const serverRelativeUrl = getServerRelativeUrl();
  return (
    url.includes(serverRelativeUrl) || url.includes(window.location.origin)
  );
}

export function getServerRelativeUrl(): string {
  const baseUrl = new URL(process.env.REACT_APP_BASE_URL || "");
  return baseUrl.pathname;
}

export function replacePageUrlPrefix(url: string): string {
  return url?.replace(/^(?:.*\/SitePages)/, window.location.origin);
}

export function isPageUrl(url: string): boolean {
  if (!url) return false;

  const Url = process.env.REACT_APP_BASE_URL;

  return url.includes(`${Url}/index.aspx`) || url.includes(`${Url}/SitePages`);
}

export function isDetailCard(type: DetailTypes) {
  return type === DetailTypes.card || type === DetailTypes.parentCard;
}

export function updateObject<T, U>(obj1: T, obj2: U): T & U {
  return { ...obj1, ...obj2 };
}

export enum ErrorMessages {
  "emptyOrHaveInvalidCharacters" = "The description contains invalid characters!",
  "long" = "This field can have no more than 255 characters!",
  "noLocation" = "You should select the target page location",
}

export const validateTitleRegExp = new RegExp("^[a-zA-Z0-9,:&/_()\\- ]*$");

export function getTitleErrorMessage(title = "") {
  if (!validateTitleRegExp.test(title)) {
    return ErrorMessages.emptyOrHaveInvalidCharacters;
  } else if (title.length > 255) {
    return ErrorMessages.long;
  }

  return "";
}
