import React, {
  FC,
  useRef,
  MutableRefObject,
  RefObject,
  useState,
} from "react";

import { Tooltip as UuiTooltip, TooltipMods } from "@epam/loveship";
import { TooltipProps } from "@epam/uui-components";

import useOutsideClick from "@App/hooks/useOutsideClick";

export interface ITooltipContentProps {
  onTooltipClose: (e: any) => void;
}

interface ITooltip extends TooltipProps, TooltipMods {
  content: (p: ITooltipContentProps) => React.ReactNode;
}

interface IContentProps {
  content: (p: ITooltipContentProps) => React.ReactNode;
  tooltipRef: MutableRefObject<any>;
}

const TooltipContent = ({ tooltipRef, content }: IContentProps) => {
  const tooltipContentRef: MutableRefObject<HTMLElement | undefined> = useRef();

  const handleClose = (e: any) => {
    tooltipRef.current.mouseClickHandler?.(e);
  };

  useOutsideClick(
    tooltipContentRef as MutableRefObject<HTMLElement>,
    handleClose,
    true
  );

  return (
    <div
      className="tooltip-content"
      ref={tooltipContentRef as RefObject<HTMLDivElement>}
    >
      {content({ onTooltipClose: handleClose } as ITooltipContentProps)}
    </div>
  );
};

export const Tooltip: FC<ITooltip & { trigger?: "click" | "hover" }> = ({
  cx,
  children,
  content,
  placement,
  trigger,
  color = "white",
}) => {
  const tooltipRef = useRef<any>({});
  const [isOpen, setIsOpen] = useState(false);

  tooltipRef.current.mouseClickHandler = (e) => {
    trigger === "click"
      ? setIsOpen(false)
      : tooltipRef.current.mouseClickHandler?.(e);
  };

  const handleClick = () => setIsOpen(!isOpen);

  return (
    <UuiTooltip
      cx={`tooltip ${cx}`}
      ref={tooltipRef}
      content={<TooltipContent tooltipRef={tooltipRef} content={content} />}
      placement={placement}
      color={color}
      value={trigger === "click" ? isOpen : undefined}
      maxWidth={1000}
    >
      <span style={{ alignSelf: "flex-start" }} onClick={handleClick}>
        {children}
      </span>
    </UuiTooltip>
  );
};
