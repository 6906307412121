export enum DetailTypes {
  "main" = "main",
  "section" = "section",
  "card" = "card",
  "parentCard" = "parentCard",
}

export type ISelectedDetailInfo = {
  type: DetailTypes;
  sectionIndex?: number;
  webPartIndex?: number;
  column?: string;
};
