import React, { FC, useState } from "react";

import { IconContainer, ScrollBars, SearchInput, Text } from "@epam/loveship";

import { AddTooltipButton } from "Components/add-tooltip-button";

import { ITooltipContentProps } from "Components/tooltip/tooltip";
import { withNaming } from "Helpers/bemClassName";
import {
  useSitePagesActions,
  useSitePagesMethods,
} from "@App/hooks/useSitePages";
import { WebPartTypes } from "SP/sitePages/sitePages.types";

import { ISectionDataColumn } from "../web-parts";
import { webPartComponents } from "./web-parts-add-web-part-list";

import "./web-parts-add-web-part.scss";
import { DetailTypes } from "Store/reducers/sitePages.reducer.types";
import { ISectionPosition } from "Components/Sections/sections-add-section/sections-add-section";

interface IAddWebPartButtonProps {
  position: ISectionPosition;
  sitePageUrl: string;
  sectionIndex: number;
  column: ISectionDataColumn;
  webPartIndex: number;
  hideBefore?: boolean;
}

type IAddWebPartContentProps = IAddWebPartButtonProps & ITooltipContentProps;

const handleFilterWebPartComponents = (searchQuery) => (webPartComponent) => {
  return webPartComponent.components.some(handleFilterComponents(searchQuery));
};

const handleFilterComponents = (searchQuery) => (component) => {
  return component.name.toLowerCase().includes(searchQuery?.toLowerCase());
};

const AddWebPartContent: FC<IAddWebPartContentProps> = ({
  onTooltipClose,
  position,
  sitePageUrl,
  sectionIndex,
  column,
  webPartIndex,
}) => {
  const cn = withNaming("add-web-part-content");
  const [search, setSearch] = useState("");
  const { updateSitePage } = useSitePagesMethods();
  const { selectSitePageDetail } = useSitePagesActions();

  const handleSelectComponent = (componentWebPart) => (e) => {
    const newWebPartIndex =
      position === "next" ? webPartIndex + 1 : webPartIndex;

    const pathKeys = [
      sitePageUrl,
      "pageInfo",
      "sections",
      sectionIndex,
      "data",
      column,
      newWebPartIndex,
    ];

    updateSitePage(pathKeys, componentWebPart);
    selectSitePageDetail({
      type:
        componentWebPart.type === WebPartTypes.RichTextEditorWebPart
          ? DetailTypes.card
          : DetailTypes.parentCard,
      sectionIndex,
      column,
      webPartIndex: newWebPartIndex,
    });
    onTooltipClose(e);
  };

  return (
    <div className={cn("")}>
      <SearchInput
        cx={cn("search")}
        value={search}
        onValueChange={(newValue) => setSearch(newValue ?? "")}
        placeholder="Search component"
        debounceDelay={500}
      />
      <ScrollBars cx={cn("scroll")}>
        {webPartComponents
          .filter(handleFilterWebPartComponents(search))
          .map(({ groupTitle, components }) => (
            <div key={groupTitle} className={cn("group")}>
              <Text
                color="night600"
                size="none"
                font="sans-semibold"
                fontSize="18"
                lineHeight="24"
              >
                {groupTitle}
              </Text>
              <div className={cn("components", ["flex flex-wrap"])}>
                {components
                  .filter(handleFilterComponents(search))
                  .map(({ name, icon, iconName, webPart }) => (
                    <div
                      key={name}
                      className={cn("component", [
                        "flex flex-col justify-center items-center cursor-pointer",
                      ])}
                      onClick={handleSelectComponent(webPart)}
                      title={name}
                    >
                      <IconContainer
                        cx={cn("icon", { [iconName]: true }, [
                          "flex items-center",
                        ])}
                        icon={icon}
                      />
                      <Text
                        cx={cn("name", ["cut-text"])}
                        color="night900"
                        size="none"
                        fontSize="14"
                        lineHeight="18"
                      >
                        {name}
                      </Text>
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </ScrollBars>
    </div>
  );
};

export const AddWebPartButton: FC<IAddWebPartButtonProps> = ({
  hideBefore,
  ...contentProps
}) => {
  return (
    <AddTooltipButton
      caption="Add Component"
      hideBefore={hideBefore}
      content={(props) => <AddWebPartContent {...props} {...contentProps} />}
    />
  );
};
