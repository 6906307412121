import { IVoidCb, IVoidCbWithParam } from "Helpers/helpers.types";
import { IPathKeys } from "Helpers/sitePageUpdater";
import {
  INewSitePageInfo,
  ISitePage,
  ISitePageVersion,
} from "SP/sitePages/sitePages.types";
import { ISelectedDetailInfo } from "Store/reducers/sitePages.reducer.types";
import { IUser, IUserWithPerms } from "./sitePage.actions.types";

export enum SitePagesActionsTypes {
  GET_SITE_PAGE_BY_URL = "[SITE PAGES] GET SITE PAGE BY URL",
  GET_SITE_PAGE_BY_URL_SUCCESS = "[SITE PAGES] GET SITE PAGE BY URL SUCCESS",
  GET_SITE_PAGE_BY_URL_FAILURE = "[SITE PAGES] GET SITE PAGE BY URL FAILURE",

  GET_CURRENT_USER_REQUEST = "[SITE PAGES] GET CURRENT USER REQUEST",
  GET_CURRENT_USER_SUCCESS = "[SITE PAGES] GET CURRENT USER SUCCESS",
  GET_CURRENT_USER_FAILURE = "[SITE PAGES] GET CURRENT USER FAILURE",

  SAVE_AS_DRAFT_SITE_PAGE = "[SITE PAGES] SAVE AS DRAFT SITE PAGE",
  SAVE_AS_DRAFT_SITE_PAGE_SUCCESS = "[SITE PAGES] SAVE AS DRAFT SITE PAGE SUCCESS",
  SAVE_AS_DRAFT_SITE_PAGE_FAILURE = "[SITE PAGES] SAVE AS DRAFT SITE PAGE FAILURE",

  CHECK_OUT_SITE_PAGE = "[SITE PAGES] CHECK OUT SITE PAGE",
  CHECK_OUT_SITE_PAGE_SUCCESS = "[SITE PAGES] CHECK OUT SITE PAGE SUCCESS",
  CHECK_OUT_SITE_PAGE_FAILURE = "[SITE PAGES] CHECK OUT SITE PAGE FAILURE",

  DISCARD_CHECK_OUT_SITE_PAGE = "[SITE PAGES] DISCARD CHECK OUT SITE PAGE",
  DISCARD_CHECK_OUT_SITE_PAGE_SUCCESS = "[SITE PAGES] DISCARD CHECK OUT SITE PAGE SUCCESS",
  DISCARD_CHECK_OUT_SITE_PAGE_FAILURE = "[SITE PAGES] DISCARD CHECK OUT SITE PAGE FAILURE",

  REPUBLISH_SITE_PAGE = "[SITE PAGES] REPUBLISH SITE PAGE",
  REPUBLISH_SITE_PAGE_SUCCESS = "[SITE PAGES] REPUBLISH SITE PAGE SUCCESS",
  REPUBLISH_SITE_PAGE_FAILURE = "[SITE PAGES] REPUBLISH SITE PAGE FAILURE",

  GET_SITE_PAGE_VERSIONS = "[SITE PAGES] GET SITE PAGE VERSIONS",
  GET_SITE_PAGE_VERSIONS_SUCCESS = "[SITE PAGES] GET SITE PAGE VERSIONS SUCCESS",
  GET_SITE_PAGE_VERSIONS_FAILURE = "[SITE PAGES] GET SITE PAGE VERSIONS FAILURE",

  SET_SITE_PAGE_VERSION_HISTORY_MODE = "[SITE PAGES] SET SITE PAGE VERSION HISTORY MODE",

  SET_SITE_PAGE_VERSION = "[SITE PAGES] SET SITE PAGE VERSION",
  SET_SITE_PAGE_VERSION_SUCCESS = "[SITE PAGES] SET SITE PAGE VERSION SUCCESS",
  SET_SITE_PAGE_VERSION_FAILURE = "[SITE PAGES] SET SITE PAGE VERSION FAILURE",

  SELECT_SITE_PAGE_VERSION = "[SITE PAGES] SELECT SITE PAGE VERSION",

  UPDATE_SITE_PAGE = "[SITE PAGES] UPDATE SITE PAGE",
  SELECT_SITE_PAGE_DETAIL = "[SITE PAGES] SELECT SITE PAGE DETAIL",
  SET_SITE_PAGE_EDIT_MODE = "[SITE PAGES] SET SITE PAGE EDIT MODE",

  SET_SITE_PAGE_IN_VALID_PROPERTY = "[SITE PAGES] SET SITE PAGE IN VALID PROPERTY",
  CLEAR_SITE_PAGE_IN_VALID = "[SITE PAGES] CLEAR SITE PAGE IN VALID",

  CLEAR_SITE_PAGE_ERRORS = "[SITE PAGES] CLEAR SITE PAGE ERRORS",
  SET_SITE_PAGE_ERROR = "[SITE PAGES] SET SITE PAGE ERROR",

  SET_CRITICAL_ERROR = "[SITE PAGES] SET CRITICAL ERROR",
}

export interface IUpdateSitePageAction {
  type: SitePagesActionsTypes.UPDATE_SITE_PAGE;
  pathKeys: IPathKeys;
  // eslint-disable-next-line
  sitePageValue: any;
}

export function updateSitePage(
  pathKeys: IPathKeys,
  sitePageValue: any
): IUpdateSitePageAction {
  return {
    type: SitePagesActionsTypes.UPDATE_SITE_PAGE,
    pathKeys,
    sitePageValue,
  };
}

export interface ISelectSitePageDetailAction {
  type: SitePagesActionsTypes.SELECT_SITE_PAGE_DETAIL;
  detailInfo: ISelectedDetailInfo;
}

export function selectSitePageDetail(
  detailInfo: ISelectedDetailInfo
): ISelectSitePageDetailAction {
  return {
    type: SitePagesActionsTypes.SELECT_SITE_PAGE_DETAIL,
    detailInfo,
  };
}

export interface ISetSitePageInValidPropertyAction {
  type: SitePagesActionsTypes.SET_SITE_PAGE_IN_VALID_PROPERTY;
  toggle: boolean;
  property: string;
}

export function setSitePageInValidProperty(
  toggle: boolean,
  property: string
): ISetSitePageInValidPropertyAction {
  return {
    type: SitePagesActionsTypes.SET_SITE_PAGE_IN_VALID_PROPERTY,
    toggle,
    property,
  };
}

export interface IClearSitePageInValidAction {
  type: SitePagesActionsTypes.CLEAR_SITE_PAGE_IN_VALID;
}

export function clearSitePageInValid(): IClearSitePageInValidAction {
  return {
    type: SitePagesActionsTypes.CLEAR_SITE_PAGE_IN_VALID,
  };
}

// SAVE AS DRAFT SITE PAGE START
export interface ISaveAsDraftSitePageRequestAction {
  type: SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE;
  sitePageId: number;
  sitePageUrl: string;
}

export function saveAsDraftSitePage(
  sitePageId: number,
  sitePageUrl: string
): ISaveAsDraftSitePageRequestAction {
  return {
    type: SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE,
    sitePageId,
    sitePageUrl,
  };
}

export interface ISaveAsDraftSitePageSuccessAction {
  type: SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE_SUCCESS;
  sitePageUrl: string;
  sitePage: ISitePage;
}

export function saveAsDraftSitePageSuccess(
  sitePageUrl: string,
  sitePage: ISitePage
): ISaveAsDraftSitePageSuccessAction {
  return {
    type: SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE_SUCCESS,
    sitePageUrl,
    sitePage: sitePage,
  };
}

export interface ISaveAsDraftSitePageFailureAction {
  type: SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE_FAILURE;
  error: string;
}

export function saveAsDraftSitePageFailure(
  error
): ISaveAsDraftSitePageFailureAction {
  return {
    type: SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE_FAILURE,
    error: error.message,
  };
}

// GET SITE PAGE BY URL
export interface IGetSitePageByUrlRequestAction {
  type: SitePagesActionsTypes.GET_SITE_PAGE_BY_URL;
  url: string;
}

export function getSitePageByUrl(url: string): IGetSitePageByUrlRequestAction {
  return {
    type: SitePagesActionsTypes.GET_SITE_PAGE_BY_URL,
    url,
  };
}

type ISitePagePayload = {
  url: string;
  sitePage: ISitePage;
};

export interface IGetSitePageByUrlSuccessAction {
  type: SitePagesActionsTypes.GET_SITE_PAGE_BY_URL_SUCCESS;
  payload: ISitePagePayload;
}

export function getSitePageByUrlSuccess(
  payload: ISitePagePayload
): IGetSitePageByUrlSuccessAction {
  return {
    type: SitePagesActionsTypes.GET_SITE_PAGE_BY_URL_SUCCESS,
    payload,
  };
}

export interface IGetSitePageByUrlFailureAction {
  type: SitePagesActionsTypes.GET_SITE_PAGE_BY_URL_FAILURE;
  error: string;
}

export function getSitePageByUrlFailure(
  error: any
): IGetSitePageByUrlFailureAction {
  return {
    type: SitePagesActionsTypes.GET_SITE_PAGE_BY_URL_FAILURE,
    error: error.message,
  };
}

// GET CURRENT USER

export interface IGetCurrentUserRequestAction {
  type: SitePagesActionsTypes.GET_CURRENT_USER_REQUEST;
  cb?: (IUser) => void;
}

export function getCurrentUser(
  cb?: (IUser) => void
): IGetCurrentUserRequestAction {
  return {
    type: SitePagesActionsTypes.GET_CURRENT_USER_REQUEST,
    cb,
  };
}

export interface IGetCurrentUserSuccessAction {
  type: SitePagesActionsTypes.GET_CURRENT_USER_SUCCESS;
  payload: IUserWithPerms;
}

export function getCurrentUserSuccess(
  user: IUserWithPerms
): IGetCurrentUserSuccessAction {
  return {
    type: SitePagesActionsTypes.GET_CURRENT_USER_SUCCESS,
    payload: user,
  };
}

export interface IGetCurrentUserFailureAction {
  type: SitePagesActionsTypes.GET_CURRENT_USER_FAILURE;
  error: string;
}

export function getCurrentUserFailure(error): IGetCurrentUserFailureAction {
  return {
    type: SitePagesActionsTypes.GET_CURRENT_USER_FAILURE,
    error: error.message,
  };
}

export interface ISetSitePageEditModeAction {
  type: SitePagesActionsTypes.SET_SITE_PAGE_EDIT_MODE;
  toggle: boolean;
  sitePageUrl: string;
}

export function setSitePageEditMode(
  toggle: boolean,
  sitePageUrl: string
): ISetSitePageEditModeAction {
  return {
    type: SitePagesActionsTypes.SET_SITE_PAGE_EDIT_MODE,
    toggle,
    sitePageUrl,
  };
}

// CHECK OUT SITE PAGE
export interface ICheckoutParams {
  sitePageId: number;
  sitePageUrl: string;
  currentUser: IUser;
  cb?: IVoidCb;
}

export interface ICheckOutSitePageRequestAction extends ICheckoutParams {
  type: SitePagesActionsTypes.CHECK_OUT_SITE_PAGE;
}

export function checkOutSitePage(
  params: ICheckoutParams
): ICheckOutSitePageRequestAction {
  return {
    type: SitePagesActionsTypes.CHECK_OUT_SITE_PAGE,
    ...params,
  };
}

export interface ICheckOutSitePageSuccessAction
  extends Omit<ICheckoutParams, "sitePageId" | "cb"> {
  type: SitePagesActionsTypes.CHECK_OUT_SITE_PAGE_SUCCESS;
}

export function checkOutSitePageSuccess(
  sitePageUrl: string,
  currentUser: IUser
): ICheckOutSitePageSuccessAction {
  return {
    type: SitePagesActionsTypes.CHECK_OUT_SITE_PAGE_SUCCESS,
    sitePageUrl,
    currentUser,
  };
}

export interface ICheckOutSitePageFailureAction {
  type: SitePagesActionsTypes.CHECK_OUT_SITE_PAGE_FAILURE;
  error: string;
}

export function checkOutSitePageFailure(
  errorMessage: string
): ICheckOutSitePageFailureAction {
  return {
    type: SitePagesActionsTypes.CHECK_OUT_SITE_PAGE_FAILURE,
    error: errorMessage,
  };
}

// DISCARD CHECK OUT SITE PAGE
export interface IDiscardCheckoutParams {
  sitePageId: number;
  sitePageUrl: string;
}

export interface IDiscardCheckOutSitePageRequestAction
  extends IDiscardCheckoutParams {
  type: SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE;
}

export function discardCheckOutSitePage(
  params: IDiscardCheckoutParams
): IDiscardCheckOutSitePageRequestAction {
  return {
    type: SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE,
    ...params,
  };
}

export interface IDiscardCheckOutSitePageSuccessAction {
  type: SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE_SUCCESS;
  sitePageUrl: string;
  sitePage: ISitePage;
}

export function discardCheckOutSitePageSuccess(
  sitePageUrl: string,
  sitePage: ISitePage
): IDiscardCheckOutSitePageSuccessAction {
  return {
    type: SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE_SUCCESS,
    sitePageUrl,
    sitePage,
  };
}

export interface IDiscardCheckOutSitePageFailureAction {
  type: SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE_FAILURE;
  error: string;
}

export function discardCheckOutSitePageFailure(
  error
): IDiscardCheckOutSitePageFailureAction {
  return {
    type: SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE_FAILURE,
    error: error.message,
  };
}

// REPUBLISH SITE PAGE
export interface IRepublishSitePageRequestAction {
  type: SitePagesActionsTypes.REPUBLISH_SITE_PAGE;
  sitePageId: number;
  sitePageUrl: string;
}

export function republishSitePage(
  sitePageId: number,
  sitePageUrl: string
): IRepublishSitePageRequestAction {
  return {
    type: SitePagesActionsTypes.REPUBLISH_SITE_PAGE,
    sitePageId,
    sitePageUrl,
  };
}

export interface IRepublishSitePageSuccessAction {
  type: SitePagesActionsTypes.REPUBLISH_SITE_PAGE_SUCCESS;
  sitePageUrl: string;
  sitePage: ISitePage;
}

export function republishSitePageSuccess(
  sitePageUrl: string,
  sitePage: ISitePage
): IRepublishSitePageSuccessAction {
  return {
    type: SitePagesActionsTypes.REPUBLISH_SITE_PAGE_SUCCESS,
    sitePageUrl,
    sitePage,
  };
}

export interface IRepublishSitePageFailureAction {
  type: SitePagesActionsTypes.REPUBLISH_SITE_PAGE_FAILURE;
  error: string;
}

export function republishSitePageFailure(
  error
): IRepublishSitePageFailureAction {
  return {
    type: SitePagesActionsTypes.REPUBLISH_SITE_PAGE_FAILURE,
    error: error.message,
  };
}

// SET SITE PAGE VERSION

export interface ISetSitePageVersionAction {
  type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION;
  pageUrl: string;
  version: ISitePageVersion;
}

export function setSitePageVersion(
  pageUrl: string,
  version: ISitePageVersion
): ISetSitePageVersionAction {
  return {
    type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION,
    pageUrl,
    version,
  };
}

export interface ISetSitePageVersionSuccessAction {
  type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION_SUCCESS;
  pageUrl: string;
  page: ISitePage;
}

export function setSitePageVersionSuccess(
  pageUrl: string,
  page: ISitePage
): ISetSitePageVersionSuccessAction {
  return {
    type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION_SUCCESS,
    pageUrl,
    page,
  };
}

export interface ISetSitePageVersionFailureAction {
  type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION_FAILURE;
  error: string;
}

export function setSitePageVersionFailure(
  error
): ISetSitePageVersionFailureAction {
  return {
    type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION_FAILURE,
    error: error.message,
  };
}

// GET SITE PAGE VERSIONS
export interface IGetSitePageVersionsAction {
  type: SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS;
  pageUrl: string;
}

export function getSitePageVersions(
  pageUrl: string
): IGetSitePageVersionsAction {
  return {
    type: SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS,
    pageUrl,
  };
}

export interface IGetSitePageVersionsSuccessAction {
  type: SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS_SUCCESS;
  pageUrl: string;
  versions: ISitePageVersion[];
}

export function getSitePageVersionsSuccess(
  pageUrl: string,
  versions: ISitePageVersion[]
): IGetSitePageVersionsSuccessAction {
  return {
    type: SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS_SUCCESS,
    pageUrl,
    versions,
  };
}

export interface IGetSitePageVersionsFailureAction {
  type: SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS_FAILURE;
  error: string;
}

export function getSitePageVersionsFailure(
  error
): IGetSitePageVersionsFailureAction {
  return {
    type: SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS_FAILURE,
    error: error.message,
  };
}

// SET SITE PAGE VERSION HISTORY MODE
export interface ISetSitePageVersionHistoryModeAction {
  type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION_HISTORY_MODE;
  pageUrl: string;
  toggle: boolean;
}

export function setSitePageVersionHistoryMode(
  pageUrl: string,
  toggle: boolean
): ISetSitePageVersionHistoryModeAction {
  return {
    type: SitePagesActionsTypes.SET_SITE_PAGE_VERSION_HISTORY_MODE,
    pageUrl,
    toggle,
  };
}

// SELECT SITE PAGE VERSION
export interface ISelectSitePageVersionAction {
  type: SitePagesActionsTypes.SELECT_SITE_PAGE_VERSION;
  pageUrl: string;
  versionIndex: number;
}

export function selectSitePageVersion(
  pageUrl: string,
  versionIndex: number
): ISelectSitePageVersionAction {
  return {
    type: SitePagesActionsTypes.SELECT_SITE_PAGE_VERSION,
    pageUrl,
    versionIndex,
  };
}

// SITE PAGE ERRORS
export interface IClearSitePageErrorsAction {
  type: SitePagesActionsTypes.CLEAR_SITE_PAGE_ERRORS;
}

export function clearSitePageErrors(): IClearSitePageErrorsAction {
  return {
    type: SitePagesActionsTypes.CLEAR_SITE_PAGE_ERRORS,
  };
}

export interface ISetSitePageErrorAction {
  type: SitePagesActionsTypes.SET_SITE_PAGE_ERROR;
  error: string;
}

export function setSitePageError(error: string): ISetSitePageErrorAction {
  return {
    type: SitePagesActionsTypes.SET_SITE_PAGE_ERROR,
    error,
  };
}

export interface ISetCriticalErrorAction {
  type: SitePagesActionsTypes.SET_CRITICAL_ERROR;
  error: string;
}

export function setCriticalError(error: any): ISetCriticalErrorAction {
  return {
    type: SitePagesActionsTypes.SET_CRITICAL_ERROR,
    error,
  };
}

export type ISitePagesActionsTypes =
  | IGetSitePageByUrlRequestAction
  | IGetSitePageByUrlSuccessAction
  | IGetSitePageByUrlFailureAction
  | IUpdateSitePageAction
  | ISelectSitePageDetailAction
  | IGetCurrentUserRequestAction
  | IGetCurrentUserSuccessAction
  | IGetCurrentUserFailureAction
  | ISetSitePageInValidPropertyAction
  | IClearSitePageInValidAction
  | ISetSitePageEditModeAction
  | ISaveAsDraftSitePageRequestAction
  | ISaveAsDraftSitePageSuccessAction
  | ISaveAsDraftSitePageFailureAction
  | ICheckOutSitePageRequestAction
  | ICheckOutSitePageSuccessAction
  | ICheckOutSitePageFailureAction
  | IDiscardCheckOutSitePageRequestAction
  | IDiscardCheckOutSitePageSuccessAction
  | IDiscardCheckOutSitePageFailureAction
  | IRepublishSitePageRequestAction
  | IRepublishSitePageSuccessAction
  | IRepublishSitePageFailureAction
  | IGetSitePageVersionsAction
  | IGetSitePageVersionsSuccessAction
  | IGetSitePageVersionsFailureAction
  | ISetSitePageVersionAction
  | ISetSitePageVersionSuccessAction
  | ISetSitePageVersionFailureAction
  | ISetSitePageVersionHistoryModeAction
  | ISelectSitePageVersionAction
  | IClearSitePageErrorsAction
  | ISetSitePageErrorAction
  | ISetCriticalErrorAction;
